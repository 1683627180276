<template>
    <div>
        <div class="main-content" v-if="profile.owners">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="block block--account">
                    <div class="profile-card" style="border-right: 0;">
                        <div class="row">
                            <div class="col-3">
                                <div class="profile-pic-wrapper">
                                    <img src="@/assets/icons/loading.gif" v-if="logoLoading" class="loader" style="position: relative; width: 100%; float: right; right: auto;" />
                                    <div v-else>
                                        <span class="edit" @click="editLogo()" v-if="owner.profile_image_loc && owner.profile_image_loc !== '/images/no-profile-img.png' && admin && !disableEdit"><i class="fas fa-pencil-alt"></i></span>
                                        <span class="seperator" v-if="owner.profile_image_loc && owner.profile_image_loc !== '/images/no-profile-img.png' && admin && !disableEdit">|</span>
                                        <span class="remove" @click="removeLogo()" v-if="owner.profile_image_loc && owner.profile_image_loc !== '/images/no-profile-img.png' && admin && !disableEdit"><i class="far fa-trash-alt"></i></span>
                                        <img :src="owner.profile_image_loc" class="profile-pic" v-if="owner.profile_image_loc && owner.profile_image_loc !== '/images/no-profile-img.png'" />
                                        <span class="add" @click="editLogo()" v-if="!owner.profile_image_loc || owner.profile_image_loc === '/images/no-profile-img.png' && admin && !disableEdit"><i class="fa-solid fa-plus"></i></span>
                                        <div class="profile-pic placeholder" v-if="!owner.profile_image_loc || owner.profile_image_loc === '/images/no-profile-img.png'">
                                            <i class="far fa-image"></i>
                                        </div>
                                        <input type="file" id="profile-image-upload" style="display: none;" @change="uploadLogo($event)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <h4 v-if="vve">{{profile.assets.vve_short}}</h4>
                                <h4 v-else>{{profile.toast.owner}}</h4>
                                <h2>{{owner.name}}</h2>
                            </div>
                        </div>
                    </div>
                    <span class="edit-button icon" @click="removeOwnerModalOpen = true" v-if="(admin || twinq) && !disableEdit" style="position: absolute; top: 0;
                    bottom: 0; margin: auto; height: 35px;"><i class="far fa-trash-alt"></i></span>
                </div>
            </div>
            <div class="container">
                <div class="tabs">
                    <div class="tab" data-testid="ownerGeneralTab" @click="changeTab('general')" :class="{active: currentTab === 'general'}">
                        <h3>{{profile.accountPage.account.general}}</h3>
                    </div>
                    <div class="tab" data-testid="ownerLocationTab" @click="changeTab('locations')" :class="{active: currentTab === 'locations'}">
                        <h3>{{profile.places.placetable.tablename}}</h3>
                    </div>
                    <div class="tab" data-testid="ownerAgreementTab" @click="changeTab('agreementflow')" :class="{active: currentTab === 'agreementflow'}" v-if="canAgreementFlow && !disableEdit && !twinq">
                        <h3>{{profile.owners.agreementflow.agreement_flow}}</h3>
                    </div>
                    <div class="tab" data-testid="ownerSupplierTab" @click="changeTab('suppliers')" :class="{active: currentTab === 'suppliers'}" v-if="admin && !disableEdit && !twinq">
                        <h3>{{profile.owners.ownertable.preferred_suppliers}}</h3>
                    </div>
                    <div class="tab" data-testid="ownerContactTab" @click="changeTab('contacts')" :class="{active: currentTab === 'contacts'}" v-if="!twinq">
                        <h3>{{profile.owners.contacts.contacts}}</h3>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'general'}">
                    <section class="incident-head full">
                        <h1 data-testid="ownerEditTitle" style="max-width: 100%;">{{profile.accountPage.account.general}}</h1>
                    </section>
                    <div style="position: relative;">
                        <div v-if="!twinq">
                            <div class="info-row" data-testid="ownerNameEditButton" @click.stop="nameEdit = true;">
                                <span v-if="!nameEdit" class="copy-button" @click.stop="copyText('name')"><i class="fa-regular fa-copy" style="color: #80808e; font-size: 16px;"></i></span>
                                <label v-if="vve">{{profile.owners.ownertable.name_vve}}
                                    <span class="edit-button edit-button--small" @click="nameEdit = true" v-if="!nameEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerNameSave" @click="saveName()" v-if="nameEdit"><i class="far fa-save"></i></span>
                                </label>
                                <label v-else>{{profile.owners.ownertable.name_owner}}
                                    <span class="edit-button edit-button--small" @click="nameEdit = true" v-if="!nameEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerNameSave" @click="saveName()" v-if="nameEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="nameEdit">
                                    <input type="text" data-testid="ownerEditName" class="form-control" v-model="owner.name" />
                                </div>
                                <div v-else>
                                    <p data-testid="ownerNameText" style="white-space: normal;">{{owner.name}}</p>
                                </div>                                      
                            </div>
                            <div class="info-row" data-testid="ownerPhoneEditButton" @click.stop="phoneEdit = true;">
                                <span v-if="!phoneEdit && (owner.phone && owner.phone !== 'null')" class="copy-button" @click.stop="copyText('phone')"><i class="fa-regular fa-copy" style="color: #80808e; font-size: 16px;"></i></span>
                                <label>{{profile.incidents.new_incident.phone}}
                                    <span class="edit-button edit-button--small" @click="phoneEdit = true" v-if="!phoneEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerPhoneSave" @click="savePhone()" v-if="phoneEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="phoneEdit">
                                    <input type="text" data-testid="ownerEditPhone" class="form-control" v-model="owner.phone" />
                                </div>  
                                <div v-else>
                                    <p data-testid="ownerPhoneText">{{owner.phone}}</p>
                                </div>       
                            </div>
                            <div class="info-row no-hover" data-testid="ownerMobileEditButton" @click.stop="mobileEdit = true;">
                                <span v-if="!mobileEdit && (owner.mobile && owner.mobile !== 'null')" class="copy-button" @click.stop="copyText('mobile')"><i class="fa-regular fa-copy" style="color: #80808e; font-size: 16px;"></i></span>
                                <label>{{profile.accountPage.account.contactmobile}}
                                    <span class="edit-button edit-button--small" @click="mobileEdit = true" v-if="!mobileEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerMobileSave" @click="saveMobile()" v-if="mobileEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="mobileEdit">          
                                    <vue-tel-input data-testid="ownerEditMobile" v-model="owner.mobile" class="form-control" v-bind="bindProps" style="padding-left: 0; margin-bottom: 0;" :class="{valid: validPhone, error: invalidPhone}" @input="validatePhone"></vue-tel-input>
                                </div>
                                <div v-else>
                                    <p data-testid="ownerMobileText">{{owner.mobile}}</p>
                                </div>
                            </div>
                            <div class="info-row" data-testid="ownerEmailEdit" @click.stop="emailEdit = true;">
                                <span v-if="!emailEdit && (owner.email && owner.email !== 'null')" class="copy-button" @click.stop="copyText('emails')"><i class="fa-regular fa-copy" style="color: #80808e; font-size: 16px;"></i></span>
                                <label>{{profile.repair_companies.repaircompanytable.corrEmail}}
                                    <span class="edit-button edit-button--small" @click="emailEdit = true" v-if="!emailEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerEmailSave" @click="saveEmail()" v-if="emailEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="emailEdit">
                                    <input type="text" data-testid="ownerEditEmail" class="form-control" v-model="tempEmail" />
                                </div>
                                <div v-else>
                                    <p data-testid="ownerEmailText">{{owner.email}}</p>
                                </div>
                            </div>
                            <div class="info-row" data-testid="ownerDescriptionEditButton" @click.stop="editDescription()">
                                <span v-if="!descriptionEdit && (owner.description && owner.description !== 'null')" class="copy-button" @click.stop="copyText('description')"><i class="fa-regular fa-copy" style="color: #80808e; font-size: 16px;"></i></span>
                                <label>{{profile.owners.ownertable.description}}
                                    <span class="edit-button edit-button--small" @click="descriptionEdit = true" v-if="!descriptionEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerDescriptionSave" @click="saveDescription()" v-if="descriptionEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="descriptionEdit">
                                    <textarea class="form-control" data-testid="ownerEditDescription" v-model="owner.description"></textarea>
                                </div>
                                <div v-else>
                                    <p data-testid="ownerDescriptionText" v-html="owner.description" style="white-space: break-spaces;"></p>
                                </div>
                            </div>
                            <div class="info-row" data-testid="ownerExIdEditButton" @click.stop="externalIdEdit = true;">
                                <span v-if="!externalIdEdit && (owner.id_external && owner.id_external !== 'null')" class="copy-button" @click.stop="copyText('externalid')"><i class="fa-regular fa-copy" style="color: #80808e; font-size: 16px;"></i></span>
                                <label>{{profile.assets.assetmodal.external_id}}
                                    <span class="edit-button edit-button--small" @click="externalIdEdit = true" v-if="!externalIdEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerExIdSave" @click="saveExternalId()" v-if="externalIdEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="externalIdEdit">
                                    <input type="text" class="form-control" data-testid="ownerEditIdExternal" v-model="owner.id_external" />
                                </div>
                                <div v-else>
                                    <p data-testid="ownerExternalText">{{owner.id_external}}</p>
                                </div>
                            </div>
                            <div class="info-row" data-testid="ownerManagerEditButton" @click.stop="managerEdit = true;">
                                <label>{{profile.owners.ownertable.manager}}
                                    <span class="edit-button edit-button--small" @click="managerEdit = true" v-if="!managerEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerManagerSave" @click="saveSelectedManager()" v-if="managerEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="managerEdit">
                                    <div class="form-field clearable" :class="{focus:managerSearchTerm.length}" style="margin-bottom: 10px;">
                                        <div>
                                            <div class="selected-info seperate" v-if="selectedManagerId" style="position: relative;">
                                                <h4 style="margin: 5px 0; font-size: 16px;">{{selectedManagerName}}</h4>
                                                <span v-if="selectedManagerId" class="remove-selection" data-testid="ownerEditClearSelectedManager" @click="clearSelectedManager()" style="top: 13px; cursor: pointer;"><i class="fas fa-times"></i></span>
                                            </div>
                                            <input type="text" ref="place" :placeholder="profile.owners.ownertable.select_owner" data-testid="ownerEditManagerSearch" class="form-control" @input="e => managerSearchTerm = e.target.value" :value="managerSearchTerm" @keyup="searchManagers()" v-if="!selectedManagerId" />
                                        </div>
                                    </div>
                                    <ul v-if="tempManagers.length" class="dropdown-list" data-testid="ownerEditManagerDropdownList" style="padding: 0; margin-top: -12px;">
                                        <li v-for="manager in tempManagers" :key="manager.id" class="dropdown-list-item" v-on:click="selectManager(manager)">
                                            <h4 style="margin: 5px 0; font-size: 16px;">{{manager.name}}</h4>
                                        </li>
                                    </ul>
                                </div>   
                                <div v-else>
                                    <p data-testid="ownerManagerText">{{assignedManager}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="info-row no-hover" data-testid="ownerNameEditButton">
                                <label v-if="vve">{{profile.owners.ownertable.name_vve}}</label>
                                <label v-else>{{profile.owners.ownertable.name_owner}}</label>
                                <div>
                                    <p data-testid="ownerNameText" style="white-space: normal;">{{owner.name}}</p>
                                </div>                                      
                            </div>
                            <div class="info-row" data-testid="ownerPhoneEditButton" @click.stop="phoneEdit = true;">
                                <label>{{profile.incidents.new_incident.phone}}
                                    <span class="edit-button edit-button--small" @click="phoneEdit = true" v-if="!phoneEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerPhoneSave" @click="savePhone()" v-if="phoneEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="phoneEdit">
                                    <input type="text" data-testid="ownerEditPhone" class="form-control" v-model="owner.phone" />
                                </div>  
                                <div v-else>
                                    <p data-testid="ownerPhoneText">{{owner.phone}}</p>
                                </div>       
                            </div>
                            <div class="info-row" data-testid="ownerMobileEditButton" v-if="owner.mobile">
                                <label>{{profile.accountPage.account.contactmobile}}</label>
                                <div>
                                    <p data-testid="ownerMobileText">{{owner.mobile}}</p>
                                </div>
                            </div>
                            <div class="info-row" data-testid="ownerEmailEdit" @click.stop="emailEdit = true;">
                                <label>{{profile.repair_companies.repaircompanytable.corrEmail}}
                                    <span class="edit-button edit-button--small" @click="emailEdit = true" v-if="!emailEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerEmailSave" @click="saveEmail()" v-if="emailEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="emailEdit">
                                    <input type="text" data-testid="ownerEditEmail" class="form-control" v-model="tempEmail" />
                                </div>
                                <div v-else>
                                    <p data-testid="ownerEmailText">{{owner.email}}</p>
                                </div>
                            </div>
                            <div class="info-row" data-testid="ownerDescriptionEditButton" @click.stop="descriptionEdit = true;">
                                <label>{{profile.owners.ownertable.description}}
                                    <span class="edit-button edit-button--small" @click="descriptionEdit = true" v-if="!descriptionEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerDescriptionSave" @click="saveDescription()" v-if="descriptionEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="descriptionEdit">
                                    <textarea class="form-control" data-testid="ownerEditDescription" v-model="owner.description"></textarea>
                                </div>
                                <div v-else>
                                    <p data-testid="ownerDescriptionText" v-html="owner.description" style="white-space: break-spaces;"></p>
                                </div>
                            </div>
                            <div class="info-row" data-testid="ownerExIdEditButton" @click.stop="externalIdEdit = true;">
                                <label>{{profile.assets.assetmodal.external_id}}
                                    <span class="edit-button edit-button--small" @click="externalIdEdit = true" v-if="!externalIdEdit"><i class="fas fa-pencil-alt"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="ownerExIdSave" @click="saveExternalId()" v-if="externalIdEdit"><i class="far fa-save"></i></span>
                                </label>
                                <div v-if="externalIdEdit">
                                    <input type="text" class="form-control" data-testid="ownerEditIdExternal" v-model="owner.id_external" />
                                </div>
                                <div v-else>
                                    <p data-testid="ownerExternalText">{{owner.id_external}}</p>
                                </div>
                            </div>
                            <div class="info-row no-hover" data-testid="ownerManagerEditButton">
                                <label>{{profile.owners.ownertable.manager}}</label>  
                                <div>
                                    <p data-testid="ownerManagerText">{{assignedManager}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="info-row" data-testid="ownerSplitEditButton" @click.stop="splitEdit = true;" v-if="vve">
                            <label>{{profile.owners.ownerdetail.split_of}}
                                <span class="edit-button edit-button--small" @click="splitEdit = true" v-if="!splitEdit"><i class="fas fa-pencil-alt"></i></span>
                                <span class="edit-button edit-button--small" data-testid="ownerSplitSave" @click="saveOwner()" v-if="splitEdit"><i class="far fa-save"></i></span>
                            </label>
                            <div v-if="splitEdit">
                                <ul v-if="owner.owner_main_split_names.length" style="padding: 0 0 0 15px; margin-bottom: 0;">
                                    <li v-for="(ownerUser, index) in owner.owner_main_split_names" :key="index" style="font-size: 14px;">{{ ownerUser.name_owner_main_split }} <i class="fas fa-times" style="float: right; cursor: pointer; font-size: 16px;" @click="removeSplit(ownerUser)"></i></li>
                                </ul>
                                <div class="dropdown-section" style="margin-bottom: 20px;">
                                    <span class="dropdown" :class="{active: ownersDropdownToggled}" v-on:click="ownersDropdownToggled = !ownersDropdownToggled">
                                        <span v-if="!selectedOwner.id_owner && vve" class="placeholder">{{profile.assets.choose_vve}}</span>
                                        <span v-if="!selectedOwner.id_owner && !vve" class="placeholder">{{profile.owners.ownerdetail.select_an_owner}}</span>
                                        <span v-if="selectedOwner.id_owner" class="selected">{{selectedOwner.name}}</span>
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </span>
                                    <div class="dropdown-list" v-if="ownersDropdownToggled" :class="{search: owners.length > 9}">
                                        <input type="text" @input="e => ownerSearchTerm = e.target.value" :placeholder="profile.owners.ownerdetail.search_vve" :value="ownerSearchTerm" @keyup="searchOwners()" v-if="owners.length > 9 && vve"  />
                                        <input type="text" @input="e => ownerSearchTerm = e.target.value" :placeholder="profile.owners.ownerdetail.search_an_owner" :value="ownerSearchTerm" @keyup="searchOwners()" v-if="owners.length > 9 && !vve"  />
                                        <ul>
                                            <li v-for="owner in owners" v-on:click="selectOwner(owner)" :key="owner.id_owner" v-show="!tempOwners.length && !ownersNoResults">{{owner.name}}</li>
                                            <li v-for="(owner, index) in tempOwners" v-on:click="selectOwner(owner)" :key="index" v-show="tempOwners.length">{{owner.name}}</li>
                                            <li v-if="ownersNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> 
                            <div v-else>
                                <ul v-if="owner.owner_main_split_names && owner.owner_main_split_names.length" style="padding: 0 0 0 15px;">
                                    <li v-for="(ownerUser, index) in owner.owner_main_split_names" :key="index" style="font-size: 14px;">{{ ownerUser.name_owner_main_split }}</li>
                                </ul>                            
                            </div>
                        </div>
                        <div class="info-row" data-testid="ownerNumberEditButton" @click.stop="numberEdit = true;" v-if="!twinq">
                            <span v-if="!numberEdit && owner.invoice_number" class="copy-button" @click.stop="copyText('number')"><i class="fa-regular fa-copy" style="color: #80808e; font-size: 16px;"></i></span>
                            <label v-if="vve">{{profile.invoicing.number}}
                                <span class="edit-button edit-button--small" @click="numberEdit = true" v-if="!numberEdit"><i class="fas fa-pencil-alt"></i></span>
                                <span class="edit-button edit-button--small" data-testid="ownerNumberSave" @click="saveVveNumber()" v-if="numberEdit"><i class="far fa-save"></i></span>
                            </label>
                            <label v-else>{{profile.incident_detail.ref}}
                                <span class="edit-button edit-button--small" @click="numberEdit = true" v-if="!numberEdit"><i class="fas fa-pencil-alt"></i></span>
                                <span class="edit-button edit-button--small" data-testid="ownerNumberSave" @click="saveVveNumber()" v-if="numberEdit"><i class="far fa-save"></i></span>
                            </label>
                            <div v-if="numberEdit">
                                <input type="text" class="form-control" v-model="owner.invoice_number" />
                            </div>
                            <div v-else>
                                <p data-testid="ownerInvoiceText">{{owner.invoice_number}}</p>
                            </div>
                        </div> 
                    </div>
                    <div class="switch-wrapper switch-wrapper--full" :class="{disabled: (!admin || disableEdit) || !owner.email, active: owner.send_incident_email}">
                        <div class="inner" @click.stop="saveIncidentEmail()">
                            <i class="fas fa-inbox"></i>
                            <p>{{profile.switches.receive_email}}</p>
                            <span class="label" v-tooltip.right="profile.owners.ownerdetail.only_use_when">{{profile.owners.ownerdetail.sendincidentemail}}</span>
                            <label class="switch" style="float: left;" v-tooltip.right="profile.owners.ownerdetail.only_use_when">
                                <span class="slider round"></span>
                            </label>
                        </div>    
                    </div>
                    <div class="switch-wrapper switch-wrapper--full" :class="{disabled: (!admin || disableEdit) || !owner.email, active: owner.send_incident_closed}">
                        <div class="inner" @click.stop="toggleSendMailClose()">
                            <i class="far fa-paper-plane"></i>
                            <p>{{profile.switches.send_email}}</p>
                            <span class="label">{{profile.employees.send_on_close}}</span>
                            <label class="switch" style="float: left; margin-top: 10px;">
                                <span class="slider round"></span>
                            </label>
                        </div>  
                    </div>
                    <div class="switch-wrapper switch-wrapper--full" v-if="vve && owner.building_manager_withhold_tenant_requests" :class="{disabled: !admin || disableEdit, active: owner.withhold_tenant_requests}">
                        <div class="inner" @click="toggleWithHold()">
                            <p>{{profile.switches.withhold_2}}</p>
                            <i class="far fa-hand-paper"></i>
                            <span class="label">{{profile.accountPage.account.withhold_tenants}} <i class="far fa-question-circle" v-tooltip.right="profile.accountPage.account.withhold_tooltip"></i></span>
                            <label class="switch" style="float: left;" v-tooltip.right="profile.accountPage.account.withhold_tooltip">
                                <span class="slider round"></span>
                            </label>
                        </div>     
                    </div>
                    <hr style="margin: 0 -20px 0 -20px;" v-if="!twinq" />
                    <div class="block block--full block--owner_detail" :class="{empty: !owner.invoice_email || !owner.invoice_address, edit: invoiceEdit}" v-if="!twinq">
                        <div class="incident-head">
                            <h3 style="margin: 0;">{{profile.invoicing.invoicing}} <i class="far fa-question-circle" v-tooltip.right="profile.invoicing.invoicing_tooltip" style="cursor: pointer;"></i></h3>
                            <span class="edit-button icon" data-testid="invoiceEditButton" @click="invoiceEdit = true" v-if="!invoiceEdit && admin && !disableEdit" style="right: 0;"><i class="fas fa-pencil-alt" style="font-size: 18px;"></i></span>
                            <span class="edit-button icon" data-testid="invoiceSaveButton" @click="saveInvoiceInfo()" v-if="invoiceEdit" style="right: 0;"><i class="fa fa-save" style="font-size: 18px;"></i></span>
                        </div>
                        <div class="incident-contents" v-if="invoiceEdit">
                            <div class="incident-info">
                                <div class="notification notification--error" v-if="invoiceError" style="margin: 20px 0;">
                                    <p style="font-size: 16px; margin-bottom: 0; color: #bd0b0b;">{{profile.invoicing.invoice_error}}</p>
                                </div>
                                <label>{{profile.invoicing.invoice_email}}</label>
                                <input type="text" class="form-control" data-testid="invoiceEmailInput" v-model="owner.invoice_email" :class="{error: invoiceError, error: invalidInvoiceEmail}" @input="validateInvoiceEmail()" />
                                <br />
                                <div class="row">
                                    <div class="col-sm-3">
                                        <label>{{profile.places.placetable.address}}</label>
                                        <input type="text" class="form-control" data-testid="invoiceAddressInput" v-model="owner.invoice_address" :class="{error: invoiceError}" />
                                    </div>
                                    <div class="col-sm-3">
                                        <label>{{profile.places.placetable.house_number}}</label>
                                        <input type="text" class="form-control" data-testid="invoiceNumberInput" v-model="owner.invoice_house_number" :class="{error: invoiceError}" />
                                    </div>
                                    <div class="col-sm-3">
                                        <label>{{profile.places.placetable.zipcode}}</label>
                                        <input type="text" class="form-control" data-testid="invoiceZipInput" v-model="owner.invoice_zipcode" :class="{error: invoiceError}" />
                                    </div>
                                    <div class="col-sm-3">
                                        <label>{{profile.places.placetable.city}}</label>
                                        <input type="text" class="form-control" data-testid="invoiceCityInput" v-model="owner.invoice_city" :class="{error: invoiceError}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="incident-contents" v-else>
                            <div class="incident-info" >
                                <div class="row">
                                    <div class="col-md-4" v-if="owner.invoice_email">
                                        <label>{{profile.invoicing.invoice_email}}</label>
                                        <p data-testid="invoiceEmailText">{{owner.invoice_email}}</p>
                                    </div>
                                    <div class="col-md-8" v-if="owner.invoice_address">
                                        <label>{{profile.invoicing.invoice_address}}</label>
                                        <p data-testid="invoiceAddressText">{{owner.invoice_address}} {{owner.invoice_house_number}}, {{owner.invoice_zipcode}} {{owner.invoice_city}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="margin-top: 0;" v-if="!twinq" />
                    <Mandates v-if="hasMandates && !twinq" :owner="owner" :disableEdit="disableEdit" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'agreementflow'}" v-if="canAgreementFlow && !disableEdit">
                    <AgreementFlow :owner="owner" :disableEdit="disableEdit" :categories="categories" :repaircompanies="repaircompanies" @getOwner="getOwner()" />
                </div>
                <div class="tab-view" :class="{active: currentTab === 'locations'}">
                    <div class="block block--owner_detail">
                        <div class="incident-head">
                            <h1 style="max-width: 100%;">{{profile.places.placetable.tablename}}</h1>
                            <span class="edit-button icon" data-testid="addOwnerLocationButton" @click="$router.push('/buildingmanager/new-location?owner=' + ownerId)" v-if="admin" style="right: 0; display:"><i class="fa-solid fa-plus"></i></span>
                        </div>
                        <div class="row" v-if="places.length">
                            <div class="col-sm-8">
                                <section class="search search-inpage">
                                    <form>
                                        <input type="text" data-testid="locationSearchInput" v-bind:placeholder="profile.assets.search_asset" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)"/>
                                        <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                                    </form>
                                </section>
                            </div>
                            <div class="col-sm-4">
                                <div class="pagination pagination--alt" v-if="!searchSuggestions.length && places.length > 25" style="margin-top: 10px;">
                                    <span class="prev" v-if="placesCurrentPage !== 1" @click="prevPage()"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="placesCurrentPage !== 1" @click="firstPage()">1</span>
                                    <span class="page-indicator">{{placesCurrentPage}} / {{placesTotalPages}}</span>
                                    <span class="page" v-if="placesCurrentPage > 2 && placesCurrentPage !== placesTotalPages" @click="lastPage()">{{placesTotalPages}}</span>
                                    <span class="next" @click="nextPage()" v-if="placesCurrentPage !== placesTotalPages"><i class="fas fa-chevron-right"></i></span>
                                </div>
                                <div class="pagination pagination--alt" v-if="searchSuggestions.length" style="margin-top: 10px;">
                                    <span class="prev" v-if="suggestionsCurrentPage !== 1" @click="prevPage()"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="suggestionsCurrentPage !== 1" @click="firstPage()">1</span>
                                    <span class="page-indicator">{{suggestionsCurrentPage}} / {{suggestionsTotalPages}}</span>
                                    <span class="page" v-if="suggestionsTotalPages > 2" @click="lastPage('currentincident')">{{suggestionsTotalPages}}</span>
                                    <span class="next" @click="nextPage()" v-if="suggestionsCurrentPage !== suggestionsTotalPages"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div> 
                        </div>
                        <div class="row" v-else>
                            <div class="col">
                                <p v-if="vve" style="font-size: 16px; margin: 15px 0 0 0;">{{ profile.owners.ownerdetail.no_locations_vve }}</p>
                                <p style="font-size: 16px; margin: 15px 0 0 0;" v-else>{{ profile.owners.ownerdetail.no_locations }}</p>
                            </div>
                        </div>
                        <div class="table-wrap" v-if="places.length">
                            <h4 class="result-counter" v-if="!searchActive && totalPlaces" style="margin-bottom: 20px;">{{totalPlaces}} <span style="text-transform: lowercase; font-weight: 500;">{{profile.places.placetable.tablename}}</span></h4>
                            <h4 class="result-counter" v-if="searchActive && !totalSearchPlaces" style="margin-bottom: 20px;">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                            <h4 class="result-counter" v-if="searchActive && totalSearchPlaces >= 1" style="margin-bottom: 20px;">{{totalSearchPlaces}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                            <table class="table table-striped" v-if="!searchActive || (searchActive && searchSuggestions.length)">
                                <thead>
                                    <tr>
                                        <th scope="col" @click="sortTable('address')" :class="{active: sortType === 'address'}">{{profile.places.placetable.address}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('house_number')" :class="{active: sortType === 'house_number'}">{{profile.places.placetable.house_number}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('zipcode')" :class="{active: sortType === 'zipcode'}">{{profile.places.placetable.zipcode}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('city')" :class="{active: sortType === 'city'}">{{profile.places.placetable.city}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('name')" :class="{active: sortType === 'name'}">{{profile.places.placetable.name}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('floor_name')" :class="{active: sortType === 'floor_name'}">{{profile.places.placetable.floor}}<i class="fas fa-sort"></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in placesInView" :key="item.id_place" v-show="!searchActive" @click="selectLocation(item.id_place)">
                                        <td>{{item.address}}</td>
                                        <td>{{item.house_number}}</td>
                                        <td>{{item.zipcode}}</td>
                                        <td>{{item.city}}</td>
                                        <td data-testid="locationNameRow">{{item.name}}</td>
                                        <td>{{item.floor_name}}</td>
                                    </tr>
                                    <tr v-for="(item, index) in suggestionsInView" :key="index" v-show="searchActive" @click="selectLocation(item.id_place)">
                                        <td>{{item.address}}</td>
                                        <td>{{item.house_number}}</td>
                                        <td>{{item.zipcode}}</td>
                                        <td>{{item.city}}</td>
                                        <td data-testid="locationNameRow">{{item.name}}</td>
                                        <td>{{item.floor_name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="notification notification--warning" v-if="searchActive && !searchSuggestions.length">{{profile.dashboard.building_manager.no_resultss}}</div>
                        </div>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'suppliers'}" v-if="admin && !disableEdit">
                    <div class="block block--autodispatch">
                        <h3>{{profile.accountPage.account.preferred_supplier_autodispatch}}</h3>
                        <div class="autodispatch-item">
                            <div class="category-select">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="dropdown-section" v-click-outside="closeCategoryDropdown">
                                            <span class="dropdown" data-testid="autoCategorySelect" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled}">
                                                <span v-if="!selectedCategory.id_incident_category" style="color: #212529; font-size: 14px;">{{profile.incident_detail.select_category}}</span>
                                                <span v-if="selectedCategory.id_incident_category && locale === 'nl'" class="selected">{{selectedCategory.dutch_name}}</span>
                                                <span v-if="selectedCategory.id_incident_category && locale === 'en'" class="selected">{{selectedCategory.english_name}}</span>
                                                <i class="fas fa-chevron-down chevron"></i></span>
                                            <div class="dropdown-list search" v-if="categoryDropdownToggled">
                                                <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" placeholder="Zoek een categorie..." @keyup="searchCategories()" v-if="categories.length > 9"  />
                                                <ul>
                                                    <li v-for="(category, index) in categories" v-on:click="selectCategory(category)" :key="index" v-show="!tempCategories.length">
                                                        <span data-testid="autoCategoryList" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                        <span data-testid="autoCategoryList" v-else>{{category.english_name}}</span>
                                                    </li>
                                                    <li v-for="category in tempCategories" v-on:click="selectCategory(category)" :key="category.id_category" v-show="tempCategories.length">
                                                        <span v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                        <span v-else>{{category.english_name}}</span>
                                                    </li>
                                                    <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="dropdown-section" v-click-outside="closeRepairCompanyDropdown">
                                            <span class="dropdown" data-testid="autoSupplierSelect" v-on:click="repairCompanyDropdownToggled = !repairCompanyDropdownToggled" :class="{active: repairCompanyDropdownToggled}">
                                                <span v-if="!selectedRepairCompany.id_repair_company" style="color: #212529; font-size: 14px;">{{profile.accountPage.account.preferred_supplier}}</span>
                                                <span v-if="selectedRepairCompany.id_repair_company" class="selected">{{selectedRepairCompany.name}}</span>
                                                <i class="fas fa-chevron-down chevron"></i></span>
                                            <div class="dropdown-list search" v-if="repairCompanyDropdownToggled">
                                                <input type="text" @input="e => repairCompanySearchTerm = e.target.value" :value="repairCompanySearchTerm" placeholder="Zoek een categorie..." @keyup="searchRepairCompanies()" v-if="repaircompanies.length > 9"  />
                                                <ul>
                                                    <li data-testid="autoSupplierList" v-for="(repaircompany, index) in repaircompanies" v-on:click="selectRepairCompany(repaircompany)" :key="index" v-show="!tempRepairCompanies.length">
                                                        {{repaircompany.name}}
                                                    </li>
                                                    <li v-for="repaircompany in tempRepairCompanies" v-on:click="selectRepairCompany(repaircompany)" :key="repaircompany.id_repair_company" v-show="tempRepairCompanies.length">
                                                        {{repaircompany.name}}
                                                    </li>
                                                    <li v-if="repairCompaniesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="switch-wrapper switch-wrapper--full no-hover" v-if="canAutoDispatch" @click.stop="autodispatch = !autodispatch" :class="{active: autodispatch}">
                                    <div class="inner">
                                        <i class="fa-regular fa-wand-sparkles"></i>                                        
                                        <p>{{profile.switches.autodispatch}}</p>
                                        <span class="label">{{profile.accountPage.account.autodispatchlabel}}</span>
                                        <label class="switch" style="float: left; margin-top: 2px;">
                                            <span data-testid="autoDispatchSlider" class="slider round"></span>
                                        </label>
                                    </div>                              
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <span class="button" data-testid="autoSubmitButton" @click="savePrefferedSupplier()" style="margin-top: 10px; float: right;">{{profile.accountPage.account.save_preffered_supplier}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br v-if="selectedAutoDispatchOptions.length" />
                        <h4 v-if="selectedAutoDispatchOptions.length">{{profile.accountPage.account.summary}} ({{selectedAutoDispatchOptions.length}})</h4>
                        <div class="autodispatch-item" v-for="(selectedAutoDispatchOption, index) in selectedAutoDispatchOptions" :key="index" :class="{edit: selectedAutoDispatchOption.editMode}">
                            <div class="row" v-if="!selectedAutoDispatchOption.editMode">
                                <div :class="{'col-sm-3': canAutoDispatch, 'col-sm-5': !canAutoDispatch}">
                                    <h3>{{profile.incident_detail.category}}</h3>
                                    <p class="capitalize" data-testid="autoCategoryText">{{selectedAutoDispatchOption.category_name}}</p>
                                </div>
                                <div :class="{'col-sm-3': canAutoDispatch, 'col-sm-5': !canAutoDispatch}">
                                    <h3>{{profile.accountPage.account.pref_sup}}</h3>
                                    <p data-testid="autoSupplierText">{{selectedAutoDispatchOption.repair_company_name}}</p>
                                </div>
                                <div class="col-sm-4" v-if="canAutoDispatch">
                                    <h3>{{profile.accountPage.account.autodispatch}}</h3>
                                    <p v-if="selectedAutoDispatchOption.auto_dispatch">{{profile.incident_detail.yes}}</p>
                                    <p data-testid="autoDispacthText" v-else>{{profile.incident_detail.no}}</p>
                                </div>
                                <div class="col-sm-2">
                                    <span class="remove-option" data-testid="autoDispatchDelete" @click="deleteAutoDispatchOption(selectedAutoDispatchOption.id_owner_incident_category_repair_company)"><i class="far fa-trash-alt"></i></span>
                                    <span class="edit-option" data-testid="autoDispatchEdit" @click="selectedAutoDispatchOption.editMode = true"><i class="fas fa-pencil-alt"></i></span>
                                </div>
                            </div>
                            <div v-if="selectedAutoDispatchOption.editMode">
                                <div class="row">
                                    <div class="col-sm-5">
                                        <h3>{{profile.incident_detail.category}}</h3>
                                        <select class="form-select" data-testid="autoDispatchCategory" v-model="selectedAutoDispatchOption.id_incident_category" @change="selectAutoDispatch()">
                                            <option data-testid="autoDispatchCategoryOption" value="">{{profile.incident_detail.select_category}}</option>
                                            <option v-for="(category, index) in categories" :value="category.id_incident_category" :key="index">{{category.dutch_name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-5">
                                        <h3>{{profile.accountPage.account.pref_sup}}</h3>
                                        <select class="form-select" data-testid="autoDispatchSpecialist" v-model="selectedAutoDispatchOption.id_repair_company" @change="selectAutoDispatch()">
                                            <option data-testid="autoDispatchSpecialistOption" value="">{{profile.incident_detail.select_a_repaircompany}}</option>
                                            <option v-for="(repaircompany, index) in repaircompanies" :value="repaircompany.id_repair_company" :key="index">{{repaircompany.name}}</option>
                                        </select>
                                    </div>
                                    <span class="remove-option" data-testid="autoDispatchSpecialistDelete" @click="deleteAutoDispatchOption(selectedAutoDispatchOption.id_owner_incident_category_repair_company)"><i class="far fa-trash-alt"></i></span>
                                    <span class="edit-option" data-testid="autoDispatchSpecialistSave" @click="saveAutoDispatchOption(selectedAutoDispatchOption)"><i class="fas fa-save"></i></span>
                                </div>
                                <div v-if="canAutoDispatch">
                                    <div class="switch-wrapper switch-wrapper--full no-hover" style="margin-top: 10px;" @click.stop="selectedAutoDispatchOption.auto_dispatch = !selectedAutoDispatchOption.auto_dispatch" :class="{active: selectedAutoDispatchOption.auto_dispatch }">
                                        <div class="inner">
                                            <i class="fa-regular fa-wand-sparkles"></i>                                        
                                            <p>{{profile.switches.autodispatch}}</p>
                                            <span class="label">{{profile.accountPage.account.autodispatchlabel}}</span>
                                            <label class="switch" style="float: left; margin-top: 2px;">
                                                <span class="slider round" data-testid="autoDispatchSlider2"></span>
                                            </label>
                                        </div>              
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="block block--autodispatch" v-if="!admin && !disableEdit && selectedAutoDispatchOptions.length">
                        <h3>{{profile.accountPage.account.autodispatchlabel}}</h3>
                        <h4 v-if="selectedAutoDispatchOptions.length">{{profile.accountPage.account.summary}}</h4>
                        <div class="autodispatch-item" v-for="(selectedAutoDispatchOption, index) in selectedAutoDispatchOptions" :key="index">
                            <div class="row" v-if="!selectedAutoDispatchOption.editMode">
                                <div class="col-sm-4">
                                    <h3>{{profile.incident_detail.category}}</h3>
                                    <p data-testid="autoDispatchCategoryText" class="capitalize">{{selectedAutoDispatchOption.category_name}}</p>
                                </div>
                                <div class="col-sm-5">
                                    <h3>{{profile.incident_detail.repair_company_comment}}</h3>
                                    <p data-testid="autoDispatchSpecialistText">{{selectedAutoDispatchOption.repair_company_name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'contacts'}">
                    <Contacts :vve="vve" :ownerId="ownerId" :disableEdit="disableEdit" />
                </div>
            </div>
        </div>
        <RemoveOwnerModal v-if="removeOwnerModalOpen" @close="removeOwnerModalOpen = false" :ownerId="ownerId" />
        <ConfirmOwnerInfoModal v-if="confirmOwnerInfoModalOpen" @close="cancelConfirmInfo()" @success="getOwner" :owner="owner" :mobileConfirm="mobileConfirm" :emailConfirm="emailConfirm" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import AgreementFlow from "@/components/owner/AgreementFlow.vue";
import Contacts from "@/components/owner/Contacts.vue";
import Mandates from "@/components/owner/Mandates.vue";
import RemoveOwnerModal from "@/components/modals/RemoveOwnerModal.vue";
import ConfirmOwnerInfoModal from "@/components/modals/ConfirmOwnerInfoModal.vue";
import { VueTelInput } from 'vue-tel-input';

export default {
    mixins: [setLocale],
    components: {
        Mandates,
        AgreementFlow,
        Contacts,
        RemoveOwnerModal,
        ConfirmOwnerInfoModal,
        VueTelInput
    },
    data() {
        return {
            profile: {},
            currentTab: 'general',
            ownerId: null,
            owner: {},
            places: [],
            placesInView: [],
            totalPlaces: 0,
            totalSearchPlaces: 0,
            sortType: '',
            placesCurrentPage: 1,
            placesTotalPages: 1,
            searchActive: false,
            searchSuggestions: [],
            sortOrder: 'asc',
            nameEdit: false,
            phoneEdit: false,
            mobileEdit: false,
            emailEdit: false,
            descriptionEdit: false,
            externalIdEdit: false,
            managerEdit: false,
            splitEdit: false,
            numberEdit: false,
            invoiceEdit: false,
            logoLoading: false,
            searchTerm: '',
            suggestionsInView: [],
            suggestionsCurrentPage: 1,
            suggestionsTotalPages: 1,
            removeOwnerModalOpen: false,
            selectedCategory: {},
            categoryDropdownToggled: false,
            categories: [],
            categorySearchTerm: '',
            tempCategories: [],
            categoriesNoResults: false,
            repairCompanyDropdownToggled: false,
            repaircompanies: [],
            repairCompanySearchTerm: '',
            tempRepairCompanies: [],
            repairCompaniesNoResults: false,
            selectedRepairCompany: {},
            autodispatch: false,
            canAutoDispatch: false,
            autoDispatchOptions: [],
            selectedAutoDispatchOptions: [],
            admin: false,
            disableEdit: false,
            managers: [],
            tempManagers: [],
            assignedManager: '',
            managerDropdownToggled: false,
            selectedManagerId: 0,
            selectedManagerName: '',
            managerSearchTerm: '',
            noManagersResults: false,
            validPhone: false,
            invalidPhone: false,
            formattedPhone: '',
            codesDropdownToggled: false,
            canChangeExternalIds: false,
            canAgreementFlow: false,
            hasMandates: false,
            countryCode: '',
            tempEmail: '',
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder:'',
                }
            },
            validInvoiceEmail: false,
            invalidInvoiceEmail: false,
            invoiceError: false,
            owners: [],
            ownersDropdownToggled: false,
            selectedOwner: {},
            tempOwners: [],
            ownerSearchTerm: '',
            ownersNoResults: false,
            confirmOwnerInfoModalOpen: false,
            emailConfirm: false,
            mobileConfirm: false

        }
    },
    computed: mapState(['vve', 'twinq']),
    created() {
        this.bindProps.inputOptions.placeholder = this.profile.accountPage.account.contactmobile;
        let queryString = window.location.search;
        this.ownerId = queryString.split('=')[1].split('&')[0];
        if (queryString.includes('&')) {
            let tabsOpen = queryString.split('&')[1];
            tabsOpen = tabsOpen.split('=')[1];
            if (tabsOpen) {
                this.currentTab = 'locations';
            }
        }
        this.getOwner();
        this.getOwners();
        this.getPlaces();
        this.getCategories();
        this.getUserRights();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        changeTab(tab) {
            this.currentTab = tab;
            if (tab === 'agreementflow' || tab === 'contacts') {
                this.$emit('getContacts', tab);
            }
        },
        getUserRights() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.admin = data.building_manager_admin;
                    self.disableEdit = data.disable_edit_basedata;
                    self.canAutoDispatch = data.can_autodispatch;
                    self.canChangeExternalIds = data.can_change_external_ids;
                    self.canAgreementFlow = data.has_agreement_flow;
                    self.hasMandates = data.has_mandates;
                });
            });
        },
        getOwner() {
            this.confirmOwnerInfoModalOpen = false;
            this.mobileEdit = false;
            this.emailEdit = false;
            this.emailConfirm = false;
            this.mobileConfirm = false;
            let self = this;
            fetch('/api/v1/owner/byid?id_owner=' + this.ownerId)
            .then(response => { response.json().then(
                function(data) {
                    self.owner = data;
                    document.title = 'S1MONE | ' + self.owner.name;
                    self.validPhone = true;
                    if (self.owner.mobile) {
                        self.owner.mobile = self.owner.mobile.replace(' ', '');
                    }
                    self.tempEmail = self.owner.email;
                    self.getManagers();
                    if (self.owner.id_owner_main_split) {
                        self.selectedOwner.id_owner = self.owner.id_owner_main_split;
                        self.selectedOwner.name = self.owner.owner_main_split_name;
                    }
                });
            });
        },
        getOwners() {
            let self = this;
            fetch('/api/v1/owner/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.owners = data.data;
                    self.owners = sortBy(self.owners, 'name');
                });
            });
        },
        searchOwners() {
            this.tempOwners = [];
            if (this.ownerSearchTerm.length > 1) {
                let searchTerm = this.ownerSearchTerm.toLowerCase();
                for (var i = 0; i < this.owners.length; i++) {
                    let owner = this.owners[i];
                    let ownerName = owner.name.toLowerCase();
                    if (ownerName.includes(searchTerm)) {
                        this.tempOwners.push(owner);
                    }
                    if (!this.tempOwners.length) {
                        this.ownersNoResults = true;
                    }
                    else {
                        this.ownersNoResults = false;
                    }
                }
            }
            else {
                this.ownersNoResults = false;
            }
        },
        getPlaces() {
            let self = this;
            fetch('/api/v1/place/byowner?id_owner=' + this.ownerId)
            .then(response => { response.json().then(
                function(data) {
                    let totalPagesRaw = data.data.length / 25;
                    self.placesTotalPages = Math.ceil(totalPagesRaw);
                    for (var j = 0; j < data.data.length; j++) {
                        let place = data.data[j];
                        if (place.floor_name === '0') {
                            if (self.locale === 'nl') {
                                place.floor_name = 'Begane grond'
                            }
                            if (self.locale === 'en') {
                                place.floor_name = 'Ground floor'
                            }
                        }
                        let placeId = place.id_place;
                        if (j < 25) {
                            self.placesInView.push(place);
                        }
                        self.places.push(place);
                    }
                    self.totalPlaces = self.places.length;
                });
            });
        },
        getManagers() {
            let self = this;
            fetch('/api/v1/buildingmanager/all_with_name?search_name=digitaal')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let manager = data.data[i];
                        if (manager.id_building_manager === self.owner.is_id_building_manager) {
                            self.assignedManager = manager.name;
                            self.selectedManagerName = manager.name;
                            self.selectedManagerId = manager.id_building_manager;
                        }
                    }
                    self.managers = data.data;
                });
            });
        },
        getCategories() {
            let self = this;
            fetch('/api/v1/incident_category/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.categories = data.data;
                    self.categories = uniqBy(self.categories, 'id_incident_category');
                    if (self.locale === 'nl') {
                        self.categories = sortBy(self.categories, 'dutch_name');
                    }
                    if (self.locale === 'en') {
                        self.categories = sortBy(self.categories, 'english_name');
                    }
                    self.getRepairCompanies();
                });
            });
        },
        searchCategories() {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i];
                    let categoryDutchName = category.dutch_name.toLowerCase();
                    let categoryEnglishName = category.english_name.toLowerCase();
                    if (categoryDutchName.includes(searchTerm) || categoryEnglishName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        getRepairCompanies() {
            let self = this;
            fetch('/api/v1/repaircompany/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.repaircompanies = data.data;
                    for (var i = 0; i < self.repaircompanies.length; i++) {
                        let repairCompany = self.repaircompanies[i];
                        repairCompany.lowerName = repairCompany.name.toLowerCase();
                    }
                    self.repaircompanies = sortBy(self.repaircompanies, 'lowerName');
                    self.getAutoDispatchOptions(self.ownerId);
                });
            });
        },
        searchRepairCompanies() {
            this.tempRepairCompanies = [];
            if (this.repairCompanySearchTerm.length > 1) {
                let searchTerm = this.repairCompanySearchTerm.toLowerCase();
                for (var i = 0; i < this.repaircompanies.length; i++) {
                    let repaircompany = this.repaircompanies[i];
                    let repaircompanyName = repaircompany.name.toLowerCase();
                    if (repaircompanyName.includes(searchTerm)) {
                        this.tempRepairCompanies.push(repaircompany);
                    }
                    if (!this.tempCategories.length) {
                        this.repairCompaniesNoResults = true;
                    }
                    else {
                        this.repairCompaniesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        saveName() {
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.ownerId + '][name]', this.owner.name);
            let self = this;
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.nameEdit = false; 
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        savePhone() {
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.ownerId + '][phone]', this.owner.phone);
            let self = this;
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.phoneEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveMobile() {
            if (!this.owner.mobile) {
                this.formattedPhone = '';
                if (this.owner.agreement_flow === 'ALWAYS_FLOW' && !this.owner.email) {
                    this.confirmOwnerInfoModalOpen = true;
                    this.mobileConfirm = true;
                }
                else {
                    const data = new URLSearchParams();
                    data.append('action', 'edit');
                    data.append('data[' + this.ownerId + '][mobile]', this.formattedPhone);
                    let self = this;
                    fetch('/api/v1/owner', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.text();
                    }).then(function(data) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.owner.mobile = self.formattedPhone;
                        self.mobileEdit = false;
                    });
                }
            }
            else {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.ownerId + '][mobile]', this.formattedPhone);
                let self = this;
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.owner.mobile = self.formattedPhone;
                    self.mobileEdit = false;
                });
            }
        },
        saveEmail() {
            if (!this.tempEmail) {
                if (this.owner.agreement_flow !== 'NO_FLOW' && !this.owner.mobile) {
                    this.confirmOwnerInfoModalOpen = true;
                    this.emailConfirm = true;
                }
                else {
                    const data = new URLSearchParams();
                    data.append('action', 'edit');
                    data.append('data[' + this.ownerId + '][email]', '');
                    let self = this;
                    fetch('/api/v1/owner', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.text();
                    }).then(function(data) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.getOwner();
                        self.emailEdit = false;
                    });
                }
            }
            else {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.ownerId + '][email]', this.tempEmail);
                let self = this;
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getOwner();
                    self.emailEdit = false;
                });
            }      
        },
        cancelConfirmInfo() {
            this.confirmOwnerInfoModalOpen = false;
            this.emailEdit = false;
            this.mobileEdit = false;
            this.getOwner();
        },
        saveMainOwner() {
            const data = new URLSearchParams();
            if (!this.selectedOwner.id_owner) {
                this.selectedOwner.id_owner = 0;
            }
            data.append('action', 'edit');
            data.append('data[' + this.ownerId + '][id_owner_main_split]', this.selectedOwner.id_owner);
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        editDescription() {
            this.descriptionEdit = true;
            this.owner.description = this.owner.description.replace(/<br>/g, '\n');
        },
        saveDescription() {
            let newText = this.owner.description.replace(/(?:\r|\n|\r\n)/g, '<br>');
            this.owner.description = newText;
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.ownerId + '][description]', newText);
            let self = this;
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.descriptionEdit = false;
            });
        },
        saveExternalId() {
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.ownerId + '][id_external]', this.owner.id_external);
            let self = this;
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.externalIdEdit = false;
            });
        },
        saveVveNumber() {
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.ownerId + '][invoice_number]', this.owner.invoice_number);
            let self = this;
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.numberEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveSelectedManager() {
            this.owner.is_id_building_manager = this.selectedManagerId;
            this.assignedManager = this.selectedManagerName;
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.ownerId + '][is_id_building_manager]', this.selectedManagerId);
            let self = this;
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.managerEdit = false;
            });
        },
        saveIncidentEmail() {
            if (this.admin && !this.disableEdit) {
                this.owner.send_incident_email = !this.owner.send_incident_email;
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.ownerId + '][send_incident_email]', this.owner.send_incident_email);
                let self = this;
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }      
        },
        validateInvoiceEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.owner.invoice_email)) {
                this.validInvoiceEmail = true;
                this.invalidInvoiceEmail = false;
            }
            else {
                this.invalidInvoiceEmail = true;
                this.validInvoiceEmail = false;
            }
            if (!this.owner.invoice_email.length) {
                this.invalidInvoiceEmail = false;
                this.validInvoiceEmail = false;
            }
        },
        toggleSendMailClose() {
            if (this.admin && !this.disableEdit) { 
                this.owner.send_incident_closed = !this.owner.send_incident_closed;
                const params = new URLSearchParams();
                params.append('data[' + this.ownerId + '][send_incident_closed]', this.owner.send_incident_closed);
                params.append('action', 'edit');
                let self = this;
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        toggleWithHold() {
            this.owner.withhold_tenant_requests = !this.owner.withhold_tenant_requests;
            if (this.admin && !this.disableEdit) {
                const params = new URLSearchParams();
                params.append('data[' + this.ownerId + '][withhold_tenant_requests]', this.owner.withhold_tenant_requests);
                params.append('action', 'edit');
                let self = this;
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        saveInvoiceInfo() {
            this.invoiceError = false;
            if (!this.owner.invoice_email) {
                if (!this.owner.invoice_address && !this.owner.invoice_house_number && !this.owner.invoice_zipcode && !this.owner.invoice_city) {
                    this.invoiceError = true;
                }
            }
            if (!this.owner.invoice_address || !this.owner.invoice_house_number || !this.owner.invoice_zipcode || !this.owner.invoice_city) {
                if (!this.owner.invoice_email) {
                    this.invoiceError = true;
                }
            }
            if (!this.owner.invoice_email.length && !this.owner.invoice_address.length && !this.owner.invoice_house_number.length && !this.owner.invoice_zipcode.length && !this.owner.invoice_city.length) {
                this.invoiceError = false;
            }
            if (this.owner.invoice_email.length && !this.invalidInvoiceEmail) {
                this.invoiceError = false;
            }
            if (this.owner.invoice_email.length && this.invalidInvoiceEmail) {
                this.invoiceError = true;
            }
            if (!this.owner.invoice_email) {
                this.owner.invoice_email = '';
            }
            if (!this.owner.invoice_address) {
                this.owner.invoice_address = '';
            }
            if (!this.owner.invoice_house_number) {
                this.owner.invoice_house_number = '';
            }
            if (!this.owner.invoice_zipcode) {
                this.owner.invoice_zipcode = '';
            }
            if (!this.owner.invoice_city) {
                this.owner.invoice_city = '';
            }
            if (!this.invoiceError && !this.invalidInvoiceEmail) {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.ownerId + '][invoice_email]', this.owner.invoice_email);
                data.append('data[' + this.ownerId + '][invoice_address]', this.owner.invoice_address);
                data.append('data[' + this.ownerId + '][invoice_house_number]', this.owner.invoice_house_number);
                data.append('data[' + this.ownerId + '][invoice_zipcode]', this.owner.invoice_zipcode);
                data.append('data[' + this.ownerId + '][invoice_city]', this.owner.invoice_city);
                let self = this;
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.invoiceEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        editLogo() {
            document.getElementById('profile-image-upload').click();
        },
        uploadLogo($event) {
            this.logoLoading = true;
            if ($event.target.files.length == 1) {
                const data = new FormData();
                data.append('file', $event.target.files[0]);
                data.append('id_owner', this.ownerId);
                let self = this;
                fetch('/api/v1/fileupload/uploadlogo', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.owner.profile_image_loc = data;
                    self.logoLoading = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        removeLogo() {
            const data = new URLSearchParams();
            data.append('id_owner', this.ownerId);
            let self = this;
            fetch('/api/v1/fileupload/deletelogo', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getOwner();
            });
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.placesInView = [];
            this.placesSortType = sorter;
            this.places = sortBy(this.places, sorter);
            if (this.sortOrder === 'desc') {
                this.places.reverse();
            }
            for (var i = 0; i < this.places.length; i++) {
                let item = this.places[i];
                this.placesCurrentPage = 1;
                if (i < 25) {
                    this.placesInView.push(item);
                }
            }
        },
        nextPage() {
            if (this.searchSuggestions.length) {
                this.suggestionsInView = [];
                this.suggestionsCurrentPage++;
                let from = (this.suggestionsCurrentPage - 1) * 25;
                let to = from + 25;
                for (var i = 0; i < this.searchSuggestions.length; i++) {
                    let item = this.searchSuggestions[i];
                    if (i > from && i <= to) {
                        this.suggestionsInView.push(item);
                    }
                }
            }
            else {
                this.placesInView = [];
                this.placesCurrentPage++;
                let from = (this.placesCurrentPage - 1) * 25;
                let to = from + 25;
                for (var j = 0; j < this.places.length; j++) {
                    let item = this.places[j];
                    if (j > from && j <= to) {
                        this.placesInView.push(item);
                    }
                }
            }
        },
        prevPage() {
            if (this.searchSuggestions.length) {
                this.suggestionsInView = [];
                this.suggestionsCurrentPage--;
                let from = this.suggestionsCurrentPage * 25;
                let to = from + 25;
                for (var i = 0; i < this.searchSuggestions.length; i++) {
                    let item = this.searchSuggestions[i];
                    if (this.suggestionsCurrentPage === 1) {
                        if (i < 25) {
                            this.suggestionsInView.push(item);
                        }
                    }
                    if (this.suggestionsCurrentPage > 1) {
                        if (i > from && i <= to) {
                            this.suggestionsInView.push(item);
                        }
                    }
                }
            }
            else {
                this.placesInView = [];
                this.placesCurrentPage--;
                let from = this.placesCurrentPage * 25;
                let to = from + 25;
                for (var j = 0; j < this.places.length; j++) {
                    let item = this.places[j];
                    if (this.placesCurrentPage === 1) {
                        if (j < 25) {
                            this.placesInView.push(item);
                        }
                    }
                    if (this.placesCurrentPage > 1) {
                        if (j > from && j <= to) {
                            this.placesInView.push(item);
                        }
                    }
                }
            }
        },
        firstPage() {
            if (this.searchSuggestions.length) {
                this.suggestionsInView = [];
                for (var i = 0; i < this.searchSuggestions.length; i++) {
                    let item = this.searchSuggestions[i];
                    this.suggestionsCurrentPage = 1;
                    if (i < 25) {
                        this.suggestionsInView.push(item);
                    }
                }
            }
            else {
                this.placesInView = [];
                for (var j = 0; j < this.places.length; j++) {
                    let item = this.places[j];
                    this.placesCurrentPage = 1;
                    if (j < 25) {
                        this.placesInView.push(item);
                    }
                }
            }
        },
        lastPage() {
            if (this.searchSuggestions.length) {
                this.suggestionsInView = [];
                for (var i = 0; i < this.searchSuggestions.length; i++) {
                    let item = this.searchSuggestions[i];
                    let lastPageStart = this.suggestionsTotalPages * 25;
                    this.suggestionsCurrentPage = this.suggestionsTotalPages;
                    if (i > lastPageStart) {
                        this.suggestionsInView.push(item);
                    }
                }
            }
            else {
                this.placesInView = [];
                for (var j = 0; j < this.places.length; j++) {
                    let item = this.places[j];
                    let lastPageStart = this.placesTotalPages * 25;
                    this.placesCurrentPage = this.placesTotalPages;
                    if (j > lastPageStart) {
                        this.placesInView.push(item);
                    }
                }
            }
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            this.suggestionsInView = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.places.length; i++) {
                    let item = this.places[i];
                    let itemAddress = item.address.toLowerCase();
                    let itemName = item.name.toLowerCase();
                    let itemZipcode = item.zipcode.toLowerCase();
                    let itemCity = item.city.toLowerCase();
                    if (itemAddress.includes(searchTerm) || itemName.includes(searchTerm) || itemZipcode.includes(searchTerm) || itemCity.includes(searchTerm)) {
                        this.searchSuggestions.push(item);
                    }
                }
            }
            else {
                this.searchActive = false;
            }
            this.searchSuggestions = uniqBy(this.searchSuggestions, 'id_place');
            let totalPagesRaw = (this.searchSuggestions.length / 25);
            this.suggestionsTotalPages = Math.ceil(totalPagesRaw);
            for (var j = 0; j < this.searchSuggestions.length; j++) {
                let item = this.searchSuggestions[j];
                if (j < 25) {
                    this.suggestionsInView.push(item);
                }
            }
            this.suggestionsInView = uniqBy(this.suggestionsInView, 'id_place');
            if($event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
            this.totalSearchPlaces = this.searchSuggestions.length;
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
            this.totalSearchPlaces = 0;
        },
        selectLocation(id) {
            this.$router.push('/buildingmanager/location-detail?id=' + id);
        },
        selectCategory(category) {
            this.categoryDropdownToggled = false;
            this.selectedCategory = category;
        },
        selectRepairCompany(repaircompany) {
            this.repairCompanyDropdownToggled = false;
            this.selectedRepairCompany = repaircompany;
        },
        getAutoDispatchOptions(id_owner) {
            let self = this;
            fetch('/api/v1/ownerincidentcategoryrepaircompany/byidowner?id_owner=' + id_owner)
            .then(response => { response.json().then(
                function(data) {
                    for (var l = 0; l < data.length; l++) {
                        let option = data[l];
                        option.editMode = false;
                    }
                    self.selectedAutoDispatchOptions = data;
                    for (var j = 0; j < self.selectedAutoDispatchOptions.length; j++) {
                        let autodispatchOption = self.selectedAutoDispatchOptions[j];
                        for (var i = 0; i < self.repaircompanies.length; i++) {
                            let repaircompany = self.repaircompanies[i];
                            if (repaircompany.id_repair_company === autodispatchOption.id_repair_company) {
                                autodispatchOption.repair_company_name = repaircompany.name;
                            }
                        }
                        for (var k = 0; k < self.categories.length; k++) {
                            let category = self.categories[k];
                            if (category.id_incident_category === autodispatchOption.id_incident_category) {
                                autodispatchOption.category_name = category.dutch_name.toLowerCase();
                            }
                        }
                    }
                    self.selectedAutoDispatchOptions = sortBy(self.selectedAutoDispatchOptions, 'id_owner_incident_category_repair_company');
                    self.selectedAutoDispatchOptions.reverse();
                });
            });
        },
        savePrefferedSupplier() {
            if (this.selectedCategory.id_incident_category && this.selectedRepairCompany.id_repair_company) {
                const data = new URLSearchParams();
                data.append('id_incident_category', this.selectedCategory.id_incident_category);
                data.append('id_repair_company', this.selectedRepairCompany.id_repair_company);
                data.append('id_owner', this.ownerId);
                if (this.autodispatch) {
                    data.append('auto_dispatch', true);
                }
                let self = this;
                fetch('/api/v1/ownerincidentcategoryrepaircompany/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Aanmaken voorkeursleverancier',
                    });
                    self.getAutoDispatchOptions(self.ownerId);
                    self.selectedCategory = {};
                    self.selectedRepairCompany = {};
                    self.autodispatch = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        deleteAutoDispatchOption(id) {
            const data = new URLSearchParams();
            data.append('id_owner_incident_category_repair_company', id);
            let self = this;
            fetch('/api/v1/ownerincidentcategoryrepaircompany/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.getAutoDispatchOptions(self.ownerId);
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveAutoDispatchOption(selectedAutoDispatchOption) {
            const data = new URLSearchParams();
            data.append('id_owner_incident_category_repair_company', selectedAutoDispatchOption.id_owner_incident_category_repair_company);
            data.append('id_incident_category', selectedAutoDispatchOption.id_incident_category);
            data.append('auto_dispatch', selectedAutoDispatchOption.auto_dispatch);
            let self = this;
            fetch('/api/v1/ownerincidentcategoryrepaircompany/updateincidentcategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
            const object = new URLSearchParams();
            object.append('id_owner_incident_category_repair_company', selectedAutoDispatchOption.id_owner_incident_category_repair_company);
            object.append('id_repair_company', selectedAutoDispatchOption.id_repair_company);
            object.append('auto_dispatch', selectedAutoDispatchOption.auto_dispatch);
            fetch('/api/v1/ownerincidentcategoryrepaircompany/updaterepaircompany', {
                method: 'post',
                body: object
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.getAutoDispatchOptions(self.ownerId);
                selectedAutoDispatchOption.editMode = false;
            });
        },
        searchManagers() {
            this.tempManagers = [];
            if (this.managerSearchTerm.length > 1) {
                let searchTerm = this.managerSearchTerm.toLowerCase();
                for (var i = 0; i < this.managers.length; i++) {
                    let manager = this.managers[i];
                    let managerName = manager.name.toLowerCase();
                    if (managerName.includes(searchTerm)) {
                        this.tempManagers.push(manager);
                    }
                    if (!this.tempManagers.length) {
                        this.noManagersResults = true;
                    }
                    else {
                        this.noManagersResults = false;
                    }
                }
            }
            else {
                this.noManagersResults = false;
            }
        },
        selectManager(manager) {
            this.managerDropdownToggled = false;
            this.selectedManagerName = manager.name;
            this.selectedManagerId = manager.id_building_manager;
            this.tempManagers = [];
            this.managerSearchTerm = '';
        },
        clearSelectedManager() {
            this.managerDropdownToggled = false;
            this.selectedManagerName = '';
            this.selectedManagerId = '';
            this.tempManagers = [];
            this.managerSearchTerm = '';
        },
        validatePhone: function (number, object) {
            this.countryCode = object.countryCode;
            if (object.countryCode === undefined) {
                object.countryCode = this.countryCode;
            }
            if (object.formatted) {
                if (object.valid) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = object.number;
                }
                else {
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.validPhone = true;
                this.invalidPhone = false;
            }
        },
        selectOwner(owner) {
            this.ownersDropdownToggled = false;
            this.selectedOwner = owner;  
        },
        saveOwner() {
            const data = new URLSearchParams();
            data.append('id_owner', this.ownerId);
            data.append('id_owner_main_split', this.selectedOwner.id_owner);
            let self = this;
            fetch('/api/v1/owner/addsplit', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.splitEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getOwner();
                self.selectedOwner = {};
            });
        },
        closeCategoryDropdown() {
            this.categoryDropdownToggled = false;
        },
        closeRepairCompanyDropdown() {
            this.repairCompanyDropdownToggled = false;
        },
        removeSplit(ownerUser) {
            const data = new URLSearchParams();
            data.append('id_owner', this.ownerId);
            data.append('id_owner_main_split', ownerUser.id_owner_main_split);
            let self = this;
            fetch('/api/v1/owner/removesplit', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getOwner();
            });
        },
        copyText(type) {
            if (type === 'name') {
                navigator.clipboard.writeText(this.owner.name);
            }
            if (type === 'phone') {
                navigator.clipboard.writeText(this.owner.phone);
            }
            if (type === 'mobile') {
                navigator.clipboard.writeText(this.owner.mobile);
            }
            if (type === 'emails') {
                navigator.clipboard.writeText(this.owner.email);
            }
            if (type === 'description') {
                let copyDescr = this.owner.description.replace(/<br>/g, '\n');
                navigator.clipboard.writeText(copyDescr);
            }
            if (type === 'externalid') {
                navigator.clipboard.writeText(this.owner.id_external);
            }
            if (type === 'number') {
                navigator.clipboard.writeText(this.owner.invoice_number);
            }
            this.$vToastify.success(this.profile.incident_detail.text_copied, this.profile.toast.changes_saved);
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
