<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-comments"></i>{{profile.owner.start_chat_ownerLong}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="startChat()">
                    <div class="row">
                        <div class="col">
                            <div class="dropdown-section" style="margin-bottom: 10px;">
                                <span class="dropdown" :class="{active: ownersDropdownToggled, error: ownerUserError }" v-on:click="ownersDropdownToggled = !ownersDropdownToggled">
                                    <span v-if="!selectedOwnerUser.id_owner_shiro_user" class="placeholder">{{profile.owner.choose_contact_person}} *</span>
                                    <span v-if="selectedOwnerUser.id_owner_shiro_user" class="selected">{{selectedOwnerUser.actual_name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="ownersDropdownToggled">
                                    <ul>
                                        <li v-for="ownerUser in ownerUsers" v-on:click="selectOwnerUser(ownerUser)" :key="ownerUser.id_owner_shiro_user">{{ownerUser.actual_name}}</li>
                                    </ul>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.chat.length, error: chatError, valid: fields.chat.length}">
                                <label>Chat *</label>
                                <textarea class="form-control" v-model="fields.chat" @input="checkChat()"></textarea>
                            </div>  
                        </div>
                    </div>
                    <button type="submit" class="button button--icon"><i class="fas fa-comments"></i>{{profile.owner.start_chat_ownerLong}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            ownersDropdownToggled: false,
            fields: {
                chat: ''
            },
            ownerUsers: [],
            selectedOwnerUser: {},
            ownerUserError: null,
            chatError: false
        }
    },
    props: ['incidentInfo'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getOwnerUsers();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getOwnerUsers() {
            let self = this;
            fetch('/api/v1/owneraccount/getownerusers?id_owner=' + this.incidentInfo.id_owner)
	        .then(response => { response.json().then(
	            function(data) {
					self.ownerUsers = data;
                    self.ownerUsers = sortBy(self.ownerUsers, 'actual_name');
				});
	        });
        },
        selectOwnerUser(ownerUser) {
            this.ownerUserError = false;
            this.ownersDropdownToggled = false;
            this.selectedOwnerUser = ownerUser;
        },
        checkChat() {
            if (!this.fields.chat) {
                this.chatError = true;
            }
            else {
                this.chatError = false;
            }
        },
        startChat() {
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Incident detail - Eigenarenchat starten',
            }); 
            if (!this.selectedOwnerUser.id_owner_shiro_user) {
                this.ownerUserError = true;
            }
            if (!this.fields.chat) {
                this.chatError = true;
            }
            if (this.selectedOwnerUser.id_owner_shiro_user && this.fields.chat) {
                this.$store.dispatch('updateOwnerChat', this.fields.chat);
                this.$store.dispatch('updateOwnerChatId', this.selectedOwnerUser.id_owner_shiro_user);
                this.closeModal();
            }
        }
    }
}
</script>
