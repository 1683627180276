<template>
    <div>
        <div class="block block--incident_detail" style="overflow: visible; padding-bottom: 0;">
            <div class="incident-head">
                <h1 style="max-width: 100%; font-size: 18px;">{{profile.incident_detail.other_details}}</h1>
            </div>
            <section class="incident-contents">
                <div class="incident-info">
                    <div class="info-row no-hover" v-if="incidentInfo.origin !== 'recurring_action'">
                        <label v-if="incidentInfo.incidentCategory && incidentInfo.incidentCategory.dutch_name !== 'INLOSSALDO' && incidentInfo.incidentCategory.dutch_name !== 'AMORTISATIESCHEMA' && incidentInfo.incidentCategory.dutch_name !== 'RENTETABEL'">{{profile.incident_detail.rating_comment}}</label>
                        <p v-if="incidentInfo.incidentCategory && incidentInfo.incidentCategory.dutch_name !== 'INLOSSALDO' && incidentInfo.incidentCategory.dutch_name !== 'AMORTISATIESCHEMA' && incidentInfo.incidentCategory.dutch_name !== 'RENTETABEL'">
                            <span data-testid="reportNameText" style="display: block; margin-bottom: 5px;" v-if="incidentInfo.reporter_name">{{incidentInfo.reporter_name}} 
                                <span class="role-label role-label--specialist" style="display: inline; float: none;" v-if="incidentInfo.origin === 'mechanic_via_app'">{{profile.incidents.incidenttable.mechanic}}</span>
                            </span>
                            <span v-if="incidentInfo.origin !== 'service_desk_planned_action' && incidentInfo.origin !== 'recurring_action'">
                                <span class="type-label" data-testid="incidentOwnerLabel" v-if="vve && incidentInfo.reporter_is_owner && industry === 'housing'">{{profile.incident_detail.owner}}</span>
                                <span class="type-label" data-testid="incidentRenterLabel" v-if="vve && !incidentInfo.reporter_is_owner && industry === 'housing' && incidentInfo.origin !== 'mechanic_via_app'">{{profile.incident_detail.renter}}</span>
                            </span>
                        </p>
                    </div>
                    <div class="info-row" @click.stop="editAddress()" data-testid="buttonEditAddress">
                        <label style="position: relative;">{{profile.incident_detail.address_reporter}}
                            <span class="edit-button edit-button--small" v-if="!addressEdit && !readOnly"><i class="fas fa-pencil-alt"></i></span>
                            <span class="edit-button edit-button--small" data-testid="buttonSaveAddress" @click.stop="saveAddress()" v-if="addressEdit"><i class="far fa-save"></i></span>
                        </label>
                        <p v-if="(incidentInfo.reporter_address || incidentInfo.reporter_house_number || incidentInfo.reporter_zipcode || incidentInfo.reporter_city) && !addressEdit" data-testid="addressText">
                            <span v-if="incidentInfo.reporter_address && incidentInfo.reporter_address !== 'null'">{{ incidentInfo.reporter_address }} </span> 
                            <span v-if="incidentInfo.reporter_house_number && incidentInfo.reporter_house_number !== 'null'">{{ incidentInfo.reporter_house_number }}, </span>
                            <span v-if="incidentInfo.reporter_zipcode !== 'null'">{{ incidentInfo.reporter_zipcode }}</span>
                            <span v-if="incidentInfo.reporter_city">, {{ incidentInfo.reporter_city }}</span></p>
                        <div class="row" v-if="addressEdit">
                            <div class="col-9">
                                <input type="text" class="form-control" data-testid="editAddressText" v-model="tempAddress" :placeholder="profile.places.placetable.address" style="margin-bottom: 10px;" />
                            </div>
                            <div class="col-3">
                                <input type="text" class="form-control" data-testid="editHouseNumberText" v-model="tempHouseNumber" placeholder="Nr." style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="row" v-if="addressEdit">
                            <div class="col-5">
                                <input type="text" class="form-control" data-testid="editZipcodeText"  v-model="tempZipcode" :placeholder="profile.places.placetable.zipcode" style="margin-bottom: 20px;"/>
                            </div>
                            <div class="col-7">
                                <input type="text" class="form-control" data-testid="editCityText" v-model="tempCity" style="margin-bottom: 20px;" />
                            </div>
                        </div>
                    </div>
                    <div class="info-row" @click="editEmail()">
                        <label style="position: relative;">{{profile.incident_detail.email_reporter}}
                            <span class="edit-button edit-button--small" v-if="!emailEdit && !readOnly"><i class="fas fa-pencil-alt"></i></span>
                            <span class="edit-button edit-button--small" @click.stop="saveEmail()" v-if="emailEdit"><i class="far fa-save"></i></span>
                        </label>
                        <p v-if="!emailEdit && incidentInfo.reporter_email_address && incidentInfo.reporter_email_address !== 'null'" v-html="incidentInfo.reporter_email_address"></p>
                        <input type="email" class="form-control" @input="validateEmail()" v-if="emailEdit" v-model="tempEmail" :class="{valid: validEmail, error: invalidEmail}" style="margin-bottom: 10px;" />
                    </div>
                   <div class="info-row" data-testid="buttonEditPhone" @click="editMobile()">
                        <label style="position: relative;">{{profile.accountPage.account.contactmobile}}
                            <span class="edit-button edit-button--small" v-if="!mobileEdit && !readOnly && incidentInfo.origin === 'service_desk_manual'"><i class="fas fa-pencil-alt"></i></span>
                            <span class="edit-button edit-button--small" data-testid="buttonSavePhone" @click.stop="saveMobile()" v-if="mobileEdit"><i class="far fa-save"></i></span>
                        </label>
                        <span v-if="!mobileEdit && incidentInfo.whatsapp" class="contactinfo whatsapp" v-tooltip.right="profile.incident_detail.reporter_uses_whatsapp" style="margin-right: 5px; margin-top: 0;"><i class="fab fa-whatsapp-square"></i></span>
                        <p data-testid="phoneText" v-if="!mobileEdit" v-html="incidentInfo.reporter_phone" style="width: 85%;"></p>
                        <span v-if="!mobileEdit && incidentInfo.reporter_phone && incidentInfo.origin !== 'mechanic_via_app' && incidentInfo.origin !== 'service_desk_planned_action'" class="contactinfo whatsapp" v-tooltip.right="profile.incident_detail.copy_phone_number" style="margin-right: 3px; float: right;" @click.stop="copyPhone()"><i class="fa-regular fa-copy" style="color: #80808e; font-size: 16px;"></i></span>
                        <vue-tel-input v-model="tempPhone" v-if="mobileEdit && incidentInfo.origin === 'service_desk_manual'" class="form-control" v-bind="bindProps" style="padding-left: 0;" :class="{valid: validPhone, error: invalidPhone}" @input="validatePhone"></vue-tel-input>           
                   </div>   
                    <div class="row" v-if="waError">
                        <div class="col">
                            <div class="notification notification--error" style="margin-top: 15px;">{{profile.incidents.new_incident.channels_error}}</div>
                        </div>
                    </div>
                    <div class="info-row" @click="localeEdit = true" data-testid="buttonEditLocale">
                        <label style="position: relative;">{{profile.places.placetable.country_reporter}}
                            <span class="edit-button edit-button--small" v-if="!localeEdit"><i class="fas fa-pencil-alt"></i></span>
                            <span class="edit-button edit-button--small" data-testid="buttonLocaleSave" @click.stop="saveLocale()" v-if="localeEdit"><i class="far fa-save"></i></span>
                        </label>
                        <div v-if="!localeEdit" class="country-list" data-testid="reporterLocaleText">
                            <span v-if="incidentInfo.reporter_locale === 'nl-NL' || incidentInfo.reporter_locale === 'nl'"><img src="@/assets/icons/flags/nl.svg">{{ profile.nationalities.dutch }}</span>
                            <span v-if="incidentInfo.reporter_locale === 'fr-FR'"><img src="@/assets/icons/flags/fr.svg">{{ profile.nationalities.french }}</span>
                            <span v-if="incidentInfo.reporter_locale === 'de-DE'"><img src="@/assets/icons/flags/de.svg">{{ profile.nationalities.german }}</span>
                            <span v-if="incidentInfo.reporter_locale === 'it-IT'"><img src="@/assets/icons/flags/it.svg">{{ profile.nationalities.italian }}</span>
                            <span v-if="incidentInfo.reporter_locale === 'en-GB'"><img src="@/assets/icons/flags/en.svg">{{ profile.nationalities.english }}</span>
                            <span v-if="incidentInfo.reporter_locale === 'pl-PL'"><img src="@/assets/icons/flags/pl.svg">{{ profile.nationalities.polish }}</span>
                            <span v-if="incidentInfo.reporter_locale === 'uk-UA'"><img src="@/assets/icons/flags/ukr.svg">{{ profile.nationalities.ukrainian }}</span>
                            <span v-if="incidentInfo.reporter_locale === 'ru-RU'"><img src="@/assets/icons/flags/ru.svg">{{ profile.nationalities.russian }}</span>
                        </div>
                        <div v-else>
                            <select class="form-select" data-testid="reporterLocaleSelect" v-model="tempLocale" style="width: 100%;">
                                <option :value="null" disabled>{{profile.text_languages.text_languagetable.choose_language}} *</option>
                                <option value="nl-NL">{{profile.nationalities.dutch}}</option>
                                <option value="fr-FR">{{profile.nationalities.french}}</option>
                                <option value="de-DE">{{profile.nationalities.german}}</option>
                                <option value="it-IT">{{profile.nationalities.italian}}</option>
                                <option value="en-GB">{{profile.nationalities.english}}</option>
                                <option value="pl-PL">{{profile.nationalities.polish}}</option>
                                <option value="uk-UA">{{profile.nationalities.ukrainian}}</option>
                                <option value="ru-RU">{{profile.nationalities.russian}}</option>
                            </select>
                        </div>
                   </div>  
                    <div class="info-row no-radius last" data-testid="buttonEditRef" @click="refNumberEdit = true">
                            <label style="position: relative;">{{profile.incident_detail.ref_number}}
                            <span class="edit-button edit-button--small" v-if="!refNumberEdit && !readOnly"><i class="fas fa-pencil-alt"></i></span>
                            <span class="edit-button edit-button--small" data-testid="buttonSaveRef" @click.stop="saveRefNumber()" v-if="refNumberEdit"><i class="far fa-save"></i></span>
                        </label>
                        <p data-testid="referenceText" v-if="!refNumberEdit && incidentInfo.id_external" v-html="incidentInfo.id_external" style="margin-bottom: 0;"></p>
                        <input type="text" class="form-control" data-testid="inputRefNumber" v-if="refNumberEdit" v-model="incidentInfo.id_external" style="margin-bottom: 10px;" />
                    </div> 
                    <div class="row" v-if="incidentInfo.reporter_phone && !incidentInfo.whatsapp">
                        <div class="col">
                            <div class="button button--icon button--small" style="background: #25D366; margin: 15px 0; display: block; width: 100%; text-align: center;" @click="addReporterToWhatsApp()"><i class="fab fa-whatsapp"></i>{{profile.incidents.new_incident.add_to_whatsapp_short}}</div>
                        </div>
                    </div>
                </div>
                <div class="incident-info wide" style="margin-top: 10px;" v-if="incidentInfo.reporter_feedback">
                    <div v-if="incidentInfo.reporter_feedback">
                        <label>{{profile.incident_detail.feedback}} {{profile.incident_detail.no_chat_name}}</label>
                        <p data-testid="reporterFeedback">{{incidentInfo.reporter_feedback}}</p>
                    </div>
                </div>
                <label v-if="incidentInfo.buildingmanager_feedback">{{profile.incident_detail.remark}}</label>
                <p data-testid="BmFeedback" v-if="incidentInfo.buildingmanager_feedback">{{incidentInfo.buildingmanager_feedback}}</p>
            </section>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { VueTelInput } from 'vue-tel-input';
import { mapState } from 'vuex';

export default {
    name: 'OtherDetails',
    mixins: [setLocale],
    components: {
        VueTelInput
    },
    data: function () {
        return {
            refNumberEdit: false,
            mobileEdit: false,
            validPhone: false,
            invalidPhone: false,
            emailEdit: false,
            validEmail: false,
            invalidEmail: false,
            addressEdit: false,
            localeEdit: false,
            tempPhone: '',
            tempEmail: '',
            tempAddress: '',
            tempHouseNumber: '',
            tempZipcode: '',
            tempCity: '',
            tempLocale: null,
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder: '',
                }
            },
            intCode: '',
            waError: false
        }
    },
    computed: mapState(['vve']),
    props: ['incidentInfo', 'readOnly', 'admin'],
    methods: {
        saveRefNumber() {
            this.refNumberEdit = false;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('id_external', this.incidentInfo.id_external);
            let self = this;
            fetch('/api/v1/incident/setidexternal', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Melder referentienummer aanpassen',
                });
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('getIncidentInfo');
            });
        },
        validatePhone: function (number, object) {
            this.intCode = object.countryCallingCode
            if (object.formatted) {
                if (object.valid) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = object.number;
                }
                else {
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.validPhone = false;
                this.invalidPhone = false;
            }
        },
        validateEmail() {
            if (this.tempEmail) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.tempEmail)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        editMobile() {
            if (this.incidentInfo.origin === 'service_desk_manual') {
                this.mobileEdit = true;
                this.tempPhone = this.incidentInfo.reporter_phone;
            }       
        },
        saveMobile() {
            if (this.validPhone) {
                this.mobileEdit = false;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('reporter_phone', this.formattedPhone);
                let self = this;
                fetch('/api/v1/incident/updatereporterphone', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Incident detail - Melder telefoonnummer aanpassen',
                    });
                    self.waError = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('getIncidentInfo');
                });
            }
        },
        saveLocale() {
            if (this.tempLocale) {
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('reporter_locale', this.tempLocale);
                let self = this;
                fetch('/api/v1/incident/updatereporterlocale', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.localeEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('getIncidentInfo');
                });
            } 
            else {
                this.localeEdit = false;
            }
        },
        editEmail() {
            this.emailEdit = true;
            this.tempEmail = this.incidentInfo.reporter_email_address;
        }, 
        saveEmail() {
            if (!this.invalidEmail) {
                this.emailEdit = false;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('reporter_email_address', this.tempEmail);
                let self = this;
                fetch('/api/v1/incident/updatereporteremail', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Incident detail - Melder e-mail aanpassen',
                    });
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('getIncidentInfo');
                });
            }
        }, 
        copyPhone() {
            navigator.clipboard.writeText(this.incidentInfo.reporter_phone);
            this.$vToastify.success(this.profile.incident_detail.phone_number_copied, this.profile.toast.changes_saved);
        },
        addReporterToWhatsApp() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('whatsapp', true);
            let self = this;
            fetch('/api/v1/incident/setwhatsapp', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Melder WhatsApp toevoegen',
                });
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                if (data.error) {
                    self.waError = true;
                }
                else {
                    self.waError = false;
                    self.$store.dispatch('updateReloadChats');
                    self.$emit('getIncidentInfo');
                }
            });
        },
        editAddress() {
            this.addressEdit = true;
            if (this.incidentInfo.reporter_address && !this.tempAddress) {
                this.tempAddress = this.incidentInfo.reporter_address;
            }
            if (this.incidentInfo.reporter_house_number && !this.tempHouseNumber) {
                this.tempHouseNumber = this.incidentInfo.reporter_house_number;
            }
            if (this.incidentInfo.reporter_zipcode && !this.tempZipcode) {
                this.tempZipcode = this.incidentInfo.reporter_zipcode;
            }
            if (this.incidentInfo.reporter_city && !this.tempCity) {
                this.tempCity = this.incidentInfo.reporter_city;
            }
        },
        saveAddress() {
            this.addressEdit = false;
            const address = new URLSearchParams();
            address.append('id_incident', this.incidentInfo.id_incident);
            address.append('reporter_address', this.tempAddress);
            let self = this;
            fetch('/api/v1/incident/updatereporteraddress', {
                method: 'post',
                body: address
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Melder adres aanpassen',
                });
            });
            const house_number = new URLSearchParams();
            house_number.append('id_incident', this.incidentInfo.id_incident);
            house_number.append('reporter_housenumber', this.tempHouseNumber);
            fetch('/api/v1/incident/updatereporterhousenumber', {
                method: 'post',
                body: house_number
            }).then((resp) => {return resp.json();
            }).then(function(data) {
            });
            const zipcode = new URLSearchParams();
            zipcode.append('id_incident', this.incidentInfo.id_incident);
            zipcode.append('reporter_zipcode', this.tempZipcode);
            fetch('/api/v1/incident/updatereporterzipcode', {
                method: 'post',
                body: zipcode
            }).then((resp) => {return resp.json();
            }).then(function(data) {
            });
            const city = new URLSearchParams();
            zipcode.append('id_incident', this.incidentInfo.id_incident);
            zipcode.append('reporter_city', this.tempCity);
            fetch('/api/v1/incident/updatereportercity', {
                method: 'post',
                body: zipcode
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('getIncidentInfo');
            });
        }
    }
}
</script>
