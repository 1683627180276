<template>
    <div>
        <div class="main-content" v-if="profile.incident_detail">
            <div class="container">  
                <div class="tabs">
                    <div class="tab" @click="currentTab = 'serviceteam'" :class="{active: currentTab === 'serviceteam'}" >
                        <h3>Serviceteam planning</h3>
                    </div>
                    <div class="tab" @click="currentTab = 'daily'" :class="{active: currentTab === 'daily'}">
                        <h3>{{profile.nav.daily_appointments}}</h3>
                    </div>
                </div>          
                <div class="tab-view" :class="{active: currentTab === 'daily'}">
                    <div class="row">
                        <div class="col">
                            <div class="block block--repaircompanies">
                                <section class="incident-head">
                                    <h1>{{profile.nav.daily_appointments}}</h1>
                                </section>
                                <br />
                                <div class="row">
                                    <div class="col-sm-6">
                                        <flat-pickr v-model="date" class="form-control" data-testid="appointmentDatePicker" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date" @on-change="getAppointments()"></flat-pickr>
                                    </div>
                                </div>
                                <table class="table table-striped" v-if="appointments.length" style="margin-top: 20px;">
                                    <thead>
                                        <tr>
                                            <th scope="col" @click="sortTable('description')">{{profile.dashboard.building_manager.message_table.place}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('description')">{{profile.incidents.incidenttable.description}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('repair_company_name')">{{profile.incidents.incidenttable.repair_company}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('mechanic_name')">{{profile.incidents.incidenttable.mechanic}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('start_date')">{{profile.incident_detail.start_time}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('end_date')">{{profile.incident_detail.end_time}}<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in appointments" :key="item.id" @click="toIncident(item.url)">
                                            <td data-testid="appointmentLocationText">{{item.place_name}}, {{item.place_address}}, {{item.asset_name}}</td>
                                            <td data-testid="appointmentDescriptionText">{{item.description}}</td>
                                            <td data-testid="appointmentSupplierText">{{item.repair_company_name}}</td>
                                            <td data-testid="appointmentSpecialistText">{{item.mechanic_name}}</td>
                                            <td data-testid="appointmentStartText">{{item.start_date}}</td>
                                            <td data-testid="appointmentEndText">{{item.end_date}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row" v-if="date && !appointments.length">
                                    <div class="col-sm-6">
                                        <div class="notification" data-testid="appointmentNotificationtext" style="margin-top: 20px;">{{ profile.dashboard.building_manager.no_results_date }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                <div class="tab-view" :class="{active: currentTab === 'serviceteam'}">
                    <div class="button-header">
                        <div class="incident-head">
                            <h1>{{profile.incident_detail.plan_appointment}}</h1>
                        </div>
                        <div class="row" style="padding-top: 20px;">
                            <div class="col">
                                <button class="button button--outline today" @click="today()" style="float: left;">Vandaag</button>
                                <div class="view-buttons">
                                    <button class="button button--outline switch-button" @click="changeView('week')" style="float: left; margin-bottom: 0;">Week</button>
                                    <button class="button button--outline switch-button" @click="changeView('day')" style="float: left; margin-bottom: 0;">Dag</button>
                                </div>
                                <button class="button button--outline next" @click="next()" style="float: right; margin-left: 5px;"><i class="fas fa-chevron-right"></i></button>
                                <button class="button button--outline prev" @click="prev()" style="float: right;"><i class="fas fa-chevron-left"></i></button>
                                <button class="button button--outline weekends" @click="toggleWeekends()" :class="{active: !calendarOptions.week.workweek}">{{profile.incident_detail.weekend}}</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h3 style="text-transform: capitalize; padding: 0; background: white; margin: 20px 0 -10px 0; float: left; font-size: 21px;">{{currentMonth}}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 filter-wrap">
                            <div class="mechanic-filter">
                                <div class="mechanics">
                                    <h3 style="padding: 15px 0 0 20px; margin-bottom: 5px; border-top: 1px solid #e5e5e5;">Filters <i class="fas fa-info-circle" v-tooltip.right="profile.incident_detail.plan_appointment_tooltip"></i></h3>
                                    <div class="mechanic-row">
                                        <div class="filter-item all" data-testid="incidentCalenderFilter" @click="saveFilter(false, false, true)">
                                            <span class="color-legend" data-testid='calenderFilterAll' :class="{active: allMechanicsActive}">
                                                <i class="fas fa-check" v-if="allMechanicsActive" style="margin: 3px 0 0 2px;"></i>
                                            </span>
                                            <p>{{profile.mechanics.mechanictable.all_specialists}}</p>
                                        </div>
                                    </div>
                                    <div class="mechanic-row" v-for="mechanic in mechanicsWithAppointment" :key="mechanic.id">
                                        <div class="filter-item" @click="saveFilter(mechanic, true, false, false)">
                                            <span class="color-legend" data-testid='calenderFilterMechanic' :style="mechanic.active ? 'background:' + mechanic.color : 'border: 2px solid ' + mechanic.color">
                                                <i class="fas fa-check"  v-if="mechanic.active" data-testid='appointmentSpecialistCheck'></i>
                                            </span>
                                            <p>{{mechanic.name}}</p>
                                        </div>
                                    </div>                                 
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-9 calendar-wrap">
                            <Calendar style="height: 800px"
                                ref="calendar"
                                :template="calendarOptions.template"
                                :calendars="calendarList"
                                :view="calendarOptions.view"
                                :usage-statistics="false"
                                :use-detail-popup="false"
                                :month="calendarOptions.month"
                                :events="calendarOptions.events"
                                :week="calendarOptions.week"
                                @clickEvent="eventEdit"
                            />
                            <div class="creation-popup" ref="draggableContainer" id="draggable-container" v-if="createNewAppointment">
                                <div class="popup-header" @mousedown="dragMouseDown">
                                    <span><i class="fas fa-expand-arrows-alt"></i>{{profile.incident_detail.drag_here}}</span>
                                </div>
                                <span @click="cancelAppointmentEdit()" class="close-popup"><i class="fa fa-times"></i></span>
                                <div v-if="eventReadOnly" class="eventreadonly">
                                    <div v-html="currentEvent.title"></div>
                                    <router-link tag="a" v-bind:to="'/buildingmanager/incident-detail?id=' + currentEvent.id" style="float: left; font-weight: 500;">{{currentEvent.id}}</router-link>
                                    <span class="status-bar" v-if="currentEvent.location && !profile['statusses'][currentEvent.location].buildingmanager" style="position: absolute; right: 5px;">
                                        <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic'"></div>
                                        <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                                        <span v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][currentEvent.location]}}</span>
                                        <span v-if="currentEvent.location && currentEvent.location === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][currentEvent.location]}}</span>
                                        <span v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && currentEvent.location !== 'cancelled'">{{profile['statusses'][currentEvent.location]}}</span>
                                    </span>
                                    <span class="status-bar" v-if="currentEvent.location && profile['statusses'][currentEvent.location].buildingmanager" style="position: absolute; right: 5px;">
                                        <div v-html="profile['status_icons'][currentEvent.location].buildingmanager" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && profile['status_icons'][currentEvent.location].buildingmanager"></div>
                                        <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && !profile['status_icons'][currentEvent.location].buildingmanager"></div>
                                        <div v-html="profile['status_icons'][currentEvent.location].buildingmanager" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic' && profile['status_icons'][currentEvent.location].buildingmanager" v-tooltip.right="incidentInfo.pause_reason"></div>
                                        <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                                        <span v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][currentEvent.location].buildingmanager}}</span>
                                        <span v-if="currentEvent.location && currentEvent.location === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][currentEvent.locations].buildingmanager}}</span>
                                        <span v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && currentEvent.location !== 'cancelled'">{{profile['statusses'][currentEvent.location].buildingmanager}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import flatPickr from 'vue-flatpickr-component';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import Calendar from '@toast-ui/vue-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.css';
import 'flatpickr/dist/flatpickr.css';
import { mapState } from 'vuex';

export default {
    mixins: [setLocale],
    components: {
        flatPickr,
        Calendar
    },
    data() {
        return {
            profile: {},
            currentTab: 'serviceteam',
            date: null,
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            },
            appointments: [],
            calendarList: [],
            allMechanicsActive: false,
            appointmentPassed: false,
            mechanicsWithAppointment: [],
            createNewAppointment: false,
            eventReadOnly: false,
            currentMonth: '',
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            },
            newAppointment: {
                date: null,
                start_time: null,
                end_time: null,
                arrival_estimate_start: null,
                arrival_estimate_end: null
            },
            calendarOptions: {
                view: 'week',
                week: {
                    dayNames: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
                    workweek: true,
                    taskView: false,
                    hourStart: 7,
                    hourEnd: 21,
                    visibleWeeksCount: 3
                },
                month: {
                    dayNames: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
                    visibleWeeksCount: 3,
                },
                template: {
                    timegridDisplayPrimaryTime({ time }) {
                        let date = time.d.d;
                        let times = moment(date).format('HH:mm');
                        return times;
                    },
                    timegridDisplayTime({ time }) {
                        return `sub timezone: ${time}`;
                    },
                    alldayTitle() {
                        return 'Uiterste<br />uitvoer';
                    }
                },
                events: []
            }
        }
    },
    computed: {
        calendarInstance() {
        if (this.$refs.calendar) {
            return this.$refs.calendar.getInstance();
        } else {
            return '';
        }
        },
        ...mapState(['serviceteam'])
    },
    created() {
        setTimeout(function() {
            this.getMechanics();
        }.bind(this), 500);
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getMechanics() {
            let self = this;
            fetch('/api/v1/mechanic/byrepaircompany?id_repair_company=' + this.serviceteam)
            .then(response => { response.json().then(
                function(data) {
                    self.mechanics = [];
                    self.mechanicsNoS1mone = [];
                    if (self.locale === 'nl') {
                        self.mechanics[0] = { 'id_mechanic': 0, 'name': 'Niet van toepassing' };
                    }
                    else {
                        self.mechanics[0] = { 'id_mechanic': 0, 'name': 'Not applicable' };
                    }                        
                    for (var i = 0; i < data.data.length; i++) {
                        let mechanic = data.data[i];   
                        if (mechanic.only_email) {
                            self.mechanicsNoS1mone.push(mechanic);
                        }
                        else {
                            self.mechanics.push(mechanic);
                        }
                    }
                    var curr = new Date;
                    self.getAppointmentsServiceteam(curr, false)
                });
            });
        },
        saveFilter(mechanic, fromInput, all, keepAll, mechanicId) {
            if (fromInput) {
                mechanic.active = !mechanic.active;
                for (var i = 0; i < this.mechanicsWithAppointment.length; i++) {
                    let mechanic = this.mechanicsWithAppointment[i];
                    if (mechanic.active) {
                        this.saveFilter(mechanic);
                    }
                }
            }
            if (mechanic) {
                if (this.allMechanicsActive) {
                    for (var i = 0; i < this.calendarOptions.events.length; i++) {
                        let event = this.calendarOptions.events[i];
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            isVisible: false
                        });
                    }
                }
                this.allMechanicsActive = false;
                for (var i = 0; i < this.calendarOptions.events.length; i++) {
                    let event = this.calendarOptions.events[i];
                    if ((mechanic.id && event.state === mechanic.id.toString()) || (!mechanic.id && !event.state)) {
                        if (mechanic.active) {
                            this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                isVisible: true,
                            });
                            if (event.location === 'on_location_mechanic'
                                && event.location === 'fixed_mechanic'
                                && event.location === 'fixed_agreed_auto'
                                && event.location === 'closed'
                                && event.location === 'cancelled'
                                && event.location === 'fixed_agreed_repaircompany'
                                && event.location === 'maintenance_checkin_mechanic'
                            ) {
                                this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                    backgroundColor: '#ccc',
                                    customStyle: {
                                        borderLeft: '3px solid ' + mechanic.color
                                    }
                                });
                            }
                        }
                        else {
                            this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                isVisible: false
                            });
                        }
                    } 
                }
            }
            if (mechanicId) {
                this.mechanicNotApplicable = false;
            }
            else {
                this.mechanicNotApplicable = true;
            }
            if (all) {
                if (!keepAll) {
                    this.allMechanicsActive = !this.allMechanicsActive;
                    for (var i = 0; i < this.calendarOptions.events.length; i++) {
                        let event = this.calendarOptions.events[i];
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            isVisible: false
                        });
                    }
                }
                if (this.allMechanicsActive || keepAll) {
                    for (var i = 0; i < this.mechanicsWithAppointment.length; i++) {
                        let mechanic = this.mechanicsWithAppointment[i];
                        mechanic.active = true;
                    }
                    for (var i = 0; i < this.calendarOptions.events.length; i++) {
                        let event = this.calendarOptions.events[i];
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            isVisible: true
                        });
                        if (event.location === 'on_location_mechanic'
                            && event.location === 'fixed_mechanic'
                            && event.location === 'fixed_agreed_auto'
                            && event.location === 'closed'
                            && event.location === 'cancelled'
                            && event.location === 'fixed_agreed_repaircompany'
                            && event.location === 'maintenance_checkin_mechanic'
                        ) {
                            this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                backgroundColor: '#ccc',
                                customStyle: {
                                    borderLeft: '3px solid ' + event.backgroundColor
                                }
                            });
                        }
                    }
                }
                else {
                    for (var i = 0; i < this.mechanicsWithAppointment.length; i++) {
                        let mechanic = this.mechanicsWithAppointment[i];
                        mechanic.active = false;
                    }
                    for (var i = 0; i < this.calendarOptions.events.length; i++) {
                        let event = this.calendarOptions.events[i];
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            isVisible: false
                        });
                    }
                }
            }
            setTimeout(function () {
                let blocks = document.querySelectorAll('.toastui-calendar-template-time span');
                blocks.forEach(function(el) {
                    el.innerHTML = el.innerHTML.replaceAll('|', '<br />')
                });
            }.bind(this), 1000);
        },
        getAppointments() {
            let date = new Date(this.date);
            let start = date.setHours(0, 0, 1);
            let end = date.setHours(23, 59, 59);
            let self = this;
            fetch('/api/v1/appointment/bybuildingmanagerforcal?fetch_start_date=' + start + '&fetch_end_date=' + end)
            .then(response => { response.json().then(
                function(data) {
                    self.appointments = data;
                    self.appointments.forEach((item) => {
                        item.start_date = moment(item.start).locale('nl').format('D MMM YYYY HH:mm');
                        item.end_date = moment(item.end).locale('nl').format('D MMM YYYY HH:mm');           
                    });
                    localStorage.setItem('S1-daily_appointments', self.date);
                });
            });
        },
        getAppointmentsServiceteam(curr, keepMechs) {
            this.calendarOptions.events = [];
            this.eventReadOnly = false;
            var first = curr.getDate() - curr.getDay();
            first = first - 31;
            var last = first + 300;
            var firstday = new Date(curr.setDate(first)).valueOf();
            var lastday = new Date(curr.setDate(last)).valueOf();
            let self = this;
            fetch('/api/v1/appointment/byrepaircompanyforcal' + '?fetchStartDate=' + firstday + '&fetchEndDate=' + lastday)
            .then(response => { response.json().then(
                function(data) {
                    let appointments = data;
                    if (appointments.length) {
                        if (!keepMechs) {
                            self.mechanicsWithAppointment = [];
                        }
                        let tempEvents = [];
                        for (var i = 0; i < appointments.length; i++) {
                            let event = appointments[i];
                            event.startTime = new Date(event.start);
                            event.endTime = new Date(event.end);
                            let calendarEvent = {};
                            calendarEvent.id = event.url;
                            calendarEvent.title = '<h3>' + event.asset_name + ' |</h3><span class="description" style="display: block; font-weight: 500;">' + event.description + '|</span><p>' + event.place_address + '</p>';
                            calendarEvent.start = new Date(event.start);
                            calendarEvent.end = new Date(event.end);
                            calendarEvent.isReadOnly = true;
                            calendarEvent.color = 'white';
                            calendarEvent.isVisible = false;
                            calendarEvent.calendarId = '1';
                            calendarEvent.attendees = [event.mechanic_name];
                            if (event.mechanic_id_mechanic) {
                                calendarEvent.state = event.mechanic_id_mechanic.toString();
                            }
                            calendarEvent.location = event.incident_status;
                            if (calendarEvent.location === 'on_location_mechanic'
                                || calendarEvent.location === 'fixed_mechanic'
                                || calendarEvent.location === 'fixed_agreed_auto'
                                || calendarEvent.location === 'closed'
                                || calendarEvent.location === 'cancelled'
                                || calendarEvent.location === 'fixed_agreed_repaircompany'
                                || calendarEvent.location === 'maintenance_checkin_mechanic'
                            ) {
                                calendarEvent.backgroundColor = '#ccc';
                                calendarEvent.isReadOnly = true;
                            }
                            let startTime = calendarEvent.start.getHours();
                            let endTime = calendarEvent.end.getHours();
                            if (startTime === 1 & endTime === 23) {
                                calendarEvent.isAllday = true;
                            }
                            calendarEvent.customStyle = {
                                borderLeft: 'none'
                            }
                            let mechanic = {
                                name: event.mechanic_name,
                                id: event.mechanic_id_mechanic,
                                active: false,
                                color: '#0867b3',
                                backgroundColor: '#0867b3'
                            }
                            if (!mechanic.id) {
                                mechanic.name = 'Zonder naam';
                            }
                            if (!keepMechs) {
                                self.mechanicsWithAppointment.push(mechanic);
                            }
                            tempEvents.push(calendarEvent);
                        }
                        if (!keepMechs) {
                            self.mechanicsWithAppointment = uniqBy(self.mechanicsWithAppointment, 'id');
                            self.mechanicsWithAppointment[0].color = '#343844';
                            self.mechanicsWithAppointment[0].backgroundColor = '#343844';
                            if (self.mechanicsWithAppointment[1]) {
                                self.mechanicsWithAppointment[1].color = '#2AB69D';
                                self.mechanicsWithAppointment[1].backgroundColor = '#2AB69D';
                            }
                            if (self.mechanicsWithAppointment[2]) {
                                self.mechanicsWithAppointment[2].color = '#E65848';
                                self.mechanicsWithAppointment[2].backgroundColor = '#E65848';
                            }
                            if (self.mechanicsWithAppointment[3]) {
                                self.mechanicsWithAppointment[3].color = '#FDC536';
                                self.mechanicsWithAppointment[3].backgroundColor = '#FDC536';
                            }
                            if (self.mechanicsWithAppointment[4]) {
                                self.mechanicsWithAppointment[4].color = '#FCF2D7';
                                self.mechanicsWithAppointment[4].backgroundColor = '#FCF2D7';
                            }
                            if (self.mechanicsWithAppointment[5]) {
                                self.mechanicsWithAppointment[5].color = '#B8D9C6';
                                self.mechanicsWithAppointment[5].backgroundColor = '#B8D9C6';
                            }
                            if (self.mechanicsWithAppointment[6]) {
                                self.mechanicsWithAppointment[6].color = '#F2C48D';
                                self.mechanicsWithAppointment[6].backgroundColor = '#F2C48D';
                            }
                            if (self.mechanicsWithAppointment[7]) {
                                self.mechanicsWithAppointment[7].color = '#F28B66';
                                self.mechanicsWithAppointment[7].backgroundColor = '#F28B66';
                            }
                            if (self.mechanicsWithAppointment[8]) {
                                self.mechanicsWithAppointment[8].color = '#F2EBE9';
                                self.mechanicsWithAppointment[8].backgroundColor = '#F2EBE9';
                            }
                            if (self.mechanicsWithAppointment[9]) {
                                self.mechanicsWithAppointment[9].color = '#F27166';
                                self.mechanicsWithAppointment[9].backgroundColor = '#F27166';
                            }
                        }
                        self.calendarList = self.mechanicsWithAppointment;
                        for (var i = 0; i < tempEvents.length; i++) {
                            let event = tempEvents[i];
                            if (event.location !== 'on_location_mechanic'
                                && event.location !== 'fixed_mechanic'
                                && event.location !== 'fixed_agreed_auto'
                                && event.location !== 'closed'
                                && event.location !== 'cancelled'
                                && event.location !== 'fixed_agreed_repaircompany'
                                && event.location !== 'maintenance_checkin_mechanic'
                            ) {
                                if (self.mechanicsWithAppointment[0].id && event.state === self.mechanicsWithAppointment[0].id.toString()) {
                                    event.backgroundColor = '#343844';
                                }
                                if (self.mechanicsWithAppointment[1] && self.mechanicsWithAppointment[1].id) {
                                    if (event.state === self.mechanicsWithAppointment[1].id.toString()) {
                                        event.backgroundColor = '#2AB69D';
                                    }
                                }
                                if (self.mechanicsWithAppointment[2] && self.mechanicsWithAppointment[2].id) {
                                    if (event.state === self.mechanicsWithAppointment[2].id.toString()) {
                                        event.backgroundColor = '#E65848';
                                    }
                                }
                                if (self.mechanicsWithAppointment[3] && self.mechanicsWithAppointment[3].id) {
                                    if (event.state === self.mechanicsWithAppointment[3].id.toString()) {
                                        event.backgroundColor = '#FDC536';
                                    }
                                }
                                if (self.mechanicsWithAppointment[4] && self.mechanicsWithAppointment[4].id) {
                                    if (event.state === self.mechanicsWithAppointment[4].id.toString()) {
                                        event.backgroundColor = '#FCF2D7';
                                    }
                                }
                                if (self.mechanicsWithAppointment[5] && self.mechanicsWithAppointment[5].id) {
                                    if (event.state === self.mechanicsWithAppointment[5].id.toString()) {
                                        event.backgroundColor = '#B8D9C6';
                                    }
                                }
                                if (self.mechanicsWithAppointment[6] && self.mechanicsWithAppointment[6].id) {
                                    if (event.state === self.mechanicsWithAppointment[6].id.toString()) {
                                        event.backgroundColor = '#F2C48D';
                                    }
                                }
                                if (self.mechanicsWithAppointment[7] && self.mechanicsWithAppointment[7].id) {
                                    if (event.state === self.mechanicsWithAppointment[7].id.toString()) {
                                        event.backgroundColor = '#F28B66';
                                    }
                                }
                                if (self.mechanicsWithAppointment[8] && self.mechanicsWithAppointment[8].id) {
                                    if (event.state === self.mechanicsWithAppointment[8].id.toString()) {
                                        event.backgroundColor = '#F2EBE9';
                                    }
                                }
                                if (self.mechanicsWithAppointment[9] && self.mechanicsWithAppointment[9]) {
                                    if (event.state === self.mechanicsWithAppointment[9].id.toString()) {
                                        event.backgroundColor = '#F27166';
                                    }
                                }
                            }
                        }
                        self.calendarOptions.events = tempEvents;
                        setTimeout(function () {
                            let date = '';
                            if (self.calendarInstance.container) {
                                date = self.calendarInstance.getDateRangeStart();
                            }
                            date = date.d.d;
                            if (self.locale === 'nl') {
                                self.currentMonth = moment(date).locale('nl').format('MMMM');
                            }
                            else {
                                self.currentMonth = moment(date).format('MMMM');
                            }
                            self.allMechanicsActive = true;
                            self.saveFilter(true, false, true, false);   
                        }.bind(this), 500);
                    }
                });
            });
        }, 
        cancelAppointmentEdit() {
            this.eventReadOnly = false;
            this.highlightActive = false;
            this.createNewAppointment = false;
            self.appointmentEdit = false;
            if (this.$el.querySelector('.toastui-calendar-grid-selection')) {
                this.$el.querySelector('.toastui-calendar-grid-selection').style.display = 'none';
            }
            this.tempAdditionalMechanic = '';
        },
        eventEdit(event) {
            let strippedTitle = event.event.title.replaceAll('|', '');
            if (this.$el.querySelector('.toastui-calendar-grid-selection')) {
                this.$el.querySelector('.toastui-calendar-grid-selection').style.display = 'none';
            }
            this.createNewAppointment = true;
            this.appointmentEdit = true;
            if (event.event.isReadOnly) {
                this.eventReadOnly = true;
                let currentEvent = {
                    title: strippedTitle,
                    id: event.event.id,
                    location: event.event.location
                }
                this.currentEvent = currentEvent;
            }
            let now = new Date();
            let nowTime = now.getTime();
            let start_time;
            let end_time;
            if (event.changes && event.changes.start) {
                start_time = event.changes.start.d.d.getTime();
                this.newAppointment.date = event.changes.start.d.d;
                this.newAppointment.start_time = moment(event.changes.start.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    start: event.changes.start.d.d
                });
            }
            else {
                start_time = event.event.start.d.d.getTime();
                this.newAppointment.date = event.event.start.d.d;
                this.newAppointment.start_time = moment(event.event.start.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    start: event.event.start.d.d
                });
            }
            if (event.changes && event.changes.end) {
                end_time = event.changes.end.d.d.getTime();
                this.newAppointment.end_time = moment(event.changes.end.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    end: event.changes.end.d.d
                });
            }
            else {
                end_time = event.event.end.d.d.getTime();
                this.newAppointment.end_time = moment(event.event.end.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    end: event.event.end.d.d
                });
            }
            if (start_time < nowTime) {
                this.pastError = true;
            }
            else {
                this.pastError = false;
            }
            if (this.latestPossible && moment(event.event.start.d.d).format('YY MM DD') === moment(now).format('YY MM DD')) {
                this.pastError = false;
            }
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.appointments = sortBy(this.appointments, sorter);
            if (this.sortOrder === 'desc') {
                this.appointments.reverse();
            }
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function (event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
            this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        },
        prev() {
            this.calendarInstance.prev();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
            this.getAppointments(date, true);
        },
        next() {
            this.calendarInstance.next();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
            this.getAppointments(date, true);
        },
        changeView(type) {
            if (type === 'week') {
                this.calendarInstance.changeView('week');
            }
            else {
                this.calendarInstance.changeView('day');
            }
        },
        today() {
            this.calendarInstance.today();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
        },
        toggleWeekends() {
            if (this.calendarOptions.week.workweek) {
                this.calendarOptions.week.workweek = false;
                this.calendarInstance.setOptions({
                    week: {
                        workweek: false
                    }
                });
            }
            else {
                this.calendarOptions.week.workweek = true;
                this.calendarInstance.setOptions({
                    week: {
                        workweek: true
                    }
                });
            }
        },
        toIncident(id) {
            this.$router.push({ path: '/buildingmanager/incident-detail', query: { id: id} });
        }
    }
}
</script>
