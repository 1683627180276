<template>
    <div>
        <div class="assign-assettypes">
            <h2>{{profile.assets.make_selection_from}}</h2>
            <div class="form-filters" v-if="assets">
                <h4>{{profile.assets.filters}}</h4>
                <div class="row" ref="results">
                    <div class="col-sm-6">
                        <div style="position: relative;" v-if="!vve">
                            <h3 v-if="industry !== 'leasing'">{{profile.assets.location_name}}</h3>
                            <h3 v-if="industry === 'leasing'">{{profile.assets.client_name}}</h3>
                            <div class="form-field clearable" :class="{focus:placeSearchTerm.length}" style="margin-bottom: 10px;">
                                <div class="selected-info seperate" v-if="selectedPlace.name">
                                    <h4>{{selectedPlace.name}}</h4>
                                    <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                                    <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                                    <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                                </div>
                                <input type="text" ref="place" class="form-control" data-testid="typeLocationSearch" :placeholder="profile.incidents.new_incident.loc_placeholder" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                            </div>
                            <ul v-if="places.length" class="dropdown-list absolute" style="padding: 0; margin-top: -12px;">
                                <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" data-testid="typeLocationList" v-on:click="selectPlace(place)">
                                    <h4>{{place.name}}</h4>
                                    <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                                    <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                                </li>
                            </ul>
                        </div>
                        <div style="position: relative;" v-else>
                            <h3 v-if="industry === 'housing'">{{profile.assets.vve}}</h3>
                            <div class="form-field clearable" :class="{focus:placeSearchTerm.length}" style="margin-bottom: 10px;">
                                <div class="selected-info seperate" v-if="selectedPlace.name">
                                    <h4>{{selectedPlace.name}}</h4>
                                    <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                                </div>
                                <input type="text" ref="place" class="form-control"  :placeholder="profile.incidents.new_incident.loc_placeholder" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                            </div>
                            <ul v-if="vveplaces.length" class="dropdown-list absolute" style="padding: 0; margin-top: -12px;">
                                <li v-for="place in vveplaces" :key="place.id_place" class="dropdown-list-item vve"  v-on:click="selectPlace(place)">
                                    <h4>{{place.name}}</h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <h3>{{profile.assets.objecttype}}</h3>
                        <div class="dropdown-section" v-click-outside="closeAssetTypesDropdown">
                            <span class="dropdown" data-testid="typeAssetSelect" :class="{active: assetTypesDropdownToggled, selected: selectedAssetTypeId}">
                                <span v-if="!selectedAssetTypeId" class="placeholder" v-on:click="assetTypesDropdownToggled = !assetTypesDropdownToggled">{{profile.assets.choose_objecttype}}</span>
                                <span v-if="selectedAssetTypeId" class="selected" v-on:click="assetTypesDropdownToggled = !assetTypesDropdownToggled">{{selectedAssetTypeName}}</span>
                                <span v-if="selectedAssetTypeId" class="remove-selection" @click="removeAssetTypeSelection()"><i class="fas fa-times"></i></span>
                                <span v-if="selectedAssetTypeId" class="seperator">|</span>
                                <i class="fas fa-chevron-down chevron" v-on:click="assetTypesDropdownToggled = !assetTypesDropdownToggled"></i>
                            </span>
                            <div class="dropdown-list absolute" v-if="assetTypesDropdownToggled" :class="{search: assetTypes.length > 9}">
                                <ul>
                                    <li data-testid="typeAssetTypeList" v-for="assetType in assetTypes" v-on:click="selectAssetType(assetType)" :key="assetType.id" v-show="!tempAssetTypes.length">{{assetType.display_name}}</li>
                                </ul>
                            </div>
                            <div class="filter-checkbox-block" :class="{active: selectAssetsWithout}" style="margin-top: 5px;">
                                <div class="checkbox-indicator" @click="selectAssetsWithoutType()">
                                    <i class="far fa-check-square" data-testid="typeNoTypeSelect" v-if="selectAssetsWithout"></i>
                                    <i class="far fa-square" data-testid="typeNoTypeSelect" v-else></i>
                                </div>
                                <label @click="selectAssetsWithoutType()">{{profile.assets.not_assigned_yet}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h3 style="margin-top: 5px;">{{profile.assets.search}}</h3>
                        <section class="search search-alt">
                            <input type="text" data-testid="typeAssetSearch" :placeholder="profile.assets.search_assettype" @input="e => searchTerm = e.target.value" :value="searchTerm" @keyup="search($event)" />
                            <span class="close-suggestions" @click="clearSearch()" v-if="searchTerm.length"><i class="fas fa-times"></i></span>
                        </section>
                    </div>             
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="dropdown-section" v-click-outside="closeAssetTypeToAssignDropdown">
                        <span class="dropdown" data-testid="typeGrantAssetSelect" :class="{active: assetTypeToAssignDropdownToggled}">
                            <span v-if="!selectedAssetTypeToAssignId" v-on:click="assetTypeToAssignDropdownToggled = !assetTypeToAssignDropdownToggled" class="placeholder">{{profile.assets.select_assettype}}</span>
                            <span v-if="selectedAssetTypeToAssignId" class="selected" v-on:click="assetTypeToAssignDropdownToggled = !assetTypeToAssignDropdownToggled">{{selectedAssetTypeToAssignName}}</span>
                            <span v-if="selectedAssetTypeToAssignId" class="remove-selection" @click="removeAssetTypeToAssignSelection()"><i class="fas fa-times"></i></span>
                            <span v-if="selectedAssetTypeToAssignId" class="seperator">|</span>
                            <i class="fas fa-chevron-down chevron" v-on:click="assetTypeToAssignDropdownToggled = !assetTypeToAssignDropdownToggled"></i>
                        </span>
                        <div class="dropdown-list" v-if="assetTypeToAssignDropdownToggled" :class="{search: assetTypes.length > 9}">
                            <input type="text" @input="e => assetTypeToAssignSearchTerm = e.target.value" :value="assetTypeToAssignSearchTerm" placeholder="Zoek een object" @keyup="searchAssetTypesToAssign()" v-if="assetTypes.length > 9"  />
                            <ul>
                                <li data-testid="typeGrantAssetList" v-for="assetTypeToAssign in assetTypes" v-on:click="selectAssetTypeToAssign(assetTypeToAssign)" :key="assetTypeToAssign.id" v-show="!tempAssetTypesToAssign.length && !assetTypesToAssignNoResults">{{assetTypeToAssign.display_name}}</li>
                                <li data-testid="typeGrantAssetList" v-for="(assetTypeToAssign, index) in tempAssetTypesToAssign" v-on:click="selectAssetTypeToAssign(assetTypeToAssign)" :key="index" v-show="tempAssetTypesToAssign.length">{{assetTypeToAssign.display_name}}</li>
                                <li data-testid="typeGrantAssetList" v-if="assetTypesToAssignNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="pagination pagination--alt" v-if="assetsTotalPages > 1">
                        <span class="prev" v-if="assetsCurrentPage !== 1" @click="prevPage()"><i class="fas fa-chevron-left"></i></span>
                        <span class="page" v-if="assetsCurrentPage !== 1" @click="firstPage()">1</span>
                        <span class="page-indicator">{{assetsCurrentPage}} / {{assetsTotalPages}}</span>
                        <span class="page" v-if="assetsCurrentPage > 2 && assetsCurrentPage !== assetsTotalPages" @click="lastPage('currentincident')">{{assetsTotalPages}}</span>
                        <span class="next" @click="nextPage()" v-if="assetsCurrentPage !== assetsTotalPages"><i class="fas fa-chevron-right"></i></span>
                    </div>
                </div>
            </div>
            <hr v-if="totalAssets" />
            <h4 class="result-counter" v-if="!totalAssets" style="margin-bottom: 20px;">{{profile.assets.no_objects_found}}</h4>
            <h4 class="result-counter" v-if="totalAssets === 1" style="margin-bottom: 20px;">{{totalAssets}} {{profile.assets.object_found}}</h4>
            <h4 class="result-counter" v-if="totalAssets > 1" style="margin-bottom: 20px;">
                {{totalAssets}} {{profile.assets.objects_found}}
                <span v-if="totalInactiveAssets"> ({{profile.assets.of_which}} {{totalInactiveAssets}} {{profile.assets.inactive_assets}})</span>
            </h4>

            <div style="overflow: hidden; margin-bottom: 20px;" v-if="totalAssets && selectedAssetTypeToAssignId">
                <div class="asset-checkbox-block" v-if="selectedAssetTypeToAssignId">
                    <div class="checkbox-indicator" @click="selectAllAssetsToAssign()">
                        <i class="far fa-check-square" data-testid="typeAssetSelectAll" v-if="selectAllAssets"></i>
                        <i class="far fa-square" data-testid="typeAssetSelectAll" v-else></i>
                    </div>
                </div>
                <div class="asset-select-button" v-if="selectedAssetTypeToAssignId">
                    <span class="button button--cta button--icon" @click="selectAssetsToAssign()" :class="{disabled: assetCounter === 0 || approveAssetModalOpen}">
                        <i class="fas fa-check-double"></i>
                        {{profile.assets.assign}}
                        <span v-if="assetCounter && !approveAssetModalOpen">{{profile.assets.to}} <b>{{assetCounter}} </b></span>
                        <span v-if="assetCounter > 1  && !approveAssetModalOpen">{{profile.assets.assets}}</span>
                        <span v-if="assetCounter === 1  && !approveAssetModalOpen">{{profile.assets.asset}}</span>
                    </span>
                </div>
            </div>
            <div class="assets-to-select" v-if="assets.length">
                <div v-for="asset in assets" :key="asset.id_asset">
                    <div class="asset-checkbox-block" v-if="selectedAssetTypeToAssignId">
                        <div class="checkbox-indicator" @click="selectAssetToAssign(asset)">
                            <i class="far fa-check-square" v-if="asset.tempIdAssetType === selectedAssetTypeToAssignId"></i>
                            <i class="far fa-square" v-else></i>
                        </div>
                    </div>
                    <div class="asset-block" :class="{assettype: asset.asset_type_name, fullwidth: !selectedAssetTypeToAssignId}" data-testid="selectAssetToEdit" @click="selectAssetToEdit(asset.id_asset)">
                        <label class="asset-type" v-if="asset.asset_type_name" v-tooltip.right="'Object typenaam'"><i class="fas fa-cube"></i> {{asset.asset_type_name}}</label>
                        <h4>{{asset.name}}</h4>
                        <span class="id">{{asset.id_asset}}</span>
                        <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                        <span class="inactive-label" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                        <span class="placename" v-if="asset.place_name"><i class="fas fa-suitcase"></i>{{asset.place_name}}</span>
                        <p class="location"><i class="fas fa-map-marker-alt"></i> {{asset.place_address}} {{asset.place_house_number}}, {{asset.place_zipcode}} {{asset.place_city}}</p>
                        <div class="properties">
                            <div class="property inactive" v-if="!asset.qr_code">
                                <i class="fas fa-qrcode"></i> {{profile.assets.no_sticker}}
                            </div>
                            <div class="property" v-if="asset.qr_code">
                                <i class="fas fa-qrcode"></i> {{profile.assets.sticker}}
                            </div>
                            <div class="property" v-if="asset.qr_code">
                                <i class="fas fa-umbrella"></i> {{profile.assets.main_object}}
                            </div>
                        </div>
                    </div>              
                </div>
            </div>
            <div style="overflow: hidden; margin: 20px 0;" v-if="totalAssets && selectedAssetTypeToAssignId">
                <div class="asset-checkbox-block" v-if="selectedAssetTypeToAssignId">
                    <div class="checkbox-indicator" @click="selectAllAssetsToAssign()">
                        <i class="far fa-check-square" data-testid="typeAssetSelectAll" v-if="selectAllAssets"></i>
                        <i class="far fa-square" data-testid="typeAssetSelectAll" v-else></i>
                    </div>
                </div>
                <div class="asset-select-button" v-if="selectedAssetTypeToAssignId">
                    <span class="button button--cta button--icon" @click="selectAssetsToAssign()" :class="{disabled: assetCounter === 0 || approveAssetModalOpen}">
                        <i class="fas fa-check-double"></i>
                        {{profile.assets.assign}}
                        <span v-if="assetCounter && !approveAssetModalOpen">{{profile.assets.to}} <b>{{assetCounter}} </b></span>
                        <span v-if="assetCounter > 1  && !approveAssetModalOpen">{{profile.assets.assets}}</span>
                        <span v-if="assetCounter === 1  && !approveAssetModalOpen">{{profile.assets.asset}}</span>
                    </span>
                </div>
            </div>
            <ApproveAssetAssignModal v-if="approveAssetModalOpen" @close="resetAssets()" @saveAssetTypes="getAssets()"
            :selectedAssets="assets"
            :allAssets="allAssets"
            :selectedAssetTypeName="selectedAssetTypeToAssignName"
            :selectedAssetTypeToAssignId="selectedAssetTypeToAssignId"
            :selectedPlaceId="selectedPlace.id_place"
            :selectedAssetId="selectedAssetId"
            :selectedAssetTypeId="selectedAssetTypeId"
            :selectAssetsWithout="selectAssetsWithout"
            :searchTerm="searchTerm"
            :assetCounter="assetCounter"
            :totalAssets="totalAssets" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { setLocale } from '@/mixins/setLocale.js';
import uniqBy from 'lodash/uniqBy';
import ApproveAssetAssignModal from "@/components/modals/ApproveAssetAssignModal.vue";

export default {
    name: 'AssignAssetTypes',
    mixins: [setLocale],
    components: {
        ApproveAssetAssignModal
    },
    props: ['admin', 'assetTypes', 'assetsWithoutType'],
    data() {
        return {
            profile: {},
            loading: false,
            tempFilterPlaces: [],
            assets: [],
            assetsSortType: '',
            assetsCurrentPage: 1,
            sortOrder: 'asc',
            placesDropdownToggled: false,
            places: [],
            vveplaces: [],
            tempPlaces: [],
            selectedPlace: {},
            placeSearchTerm: '',
            placesNoResults: false,
            assetsTotalPages: 1,
            assetTypesDropdownToggled: false,
            tempAssetTypes: [],
            selectedAssetTypeId: 0,
            selectedAssetTypeName: '',
            assetTypeSearchTerm: '',
            assetTypeToAssignDropdownToggled: false,
            assetTypesToAssignNoResults: false,
            tempAssetTypesToAssign: [],
            selectedAssetTypeToAssignId: 0,
            selectedAssetTypeToAssignName: '',
            assetTypeToAssignSearchTerm: '',
            selectedAssetId: 0,
            filterSticker: false,
            filterActive: false,
            filterMain: false,
            selectAllAssets: false,
            assignToAllAssets: false,
            approveAssetModalOpen: false,
            assetCounter: 0,
            assetToEditId: 0,
            searchTerm: '',
            selectAssetsWithout: false,
            totalAssets: 0,
            totalInactiveAssets: null,
            allAssets: false,
        }
    },
    computed: mapState(['loadAssets', 'vve']),
    watch: {
        loadAssets() { 
            if (this.loadAssets) {
                this.getAssets();
                this.$store.dispatch('updateLoadAssets', false);
            }
        }
    },
    created() {
        if (this.assetsWithoutType) {
            this.selectAssetsWithoutType();
        } else {
            this.getAssets();
        }
    },
    methods: {
        getAssets() {
            this.selectedAssetTypeToAssignId = 0;
            this.selectedAssetTypeToAssignName = '';
            this.assetCounter = 0;
            this.approveAssetModalOpen = false;
            this.loading = true;
            let from;
            if (this.assetsCurrentPage === 1) {
                from = 0;
            }
            else {
                from = (this.assetsCurrentPage - 1) * 25;
            }
            let fromParam = '&offset=' + from;
            let searchParam = '';
            let placeParam = '';
            let assetParam = '';
            let assetTypeParam = '';
            if (this.selectedPlace.name) {
                placeParam = '&place_name=' + this.selectedPlace.name;
            }
            if (this.selectedAssetName) {
                assetParam = '&search_name=' + this.selectedAssetId;
            }
            if (this.searchTerm) {
                searchParam = '&search_name=' + this.searchTerm;
            }
            if (this.selectedAssetTypeId) {
                assetTypeParam = '&id_asset_type=' + this.selectedAssetTypeId;
            }
            if (this.selectAssetsWithout) {
                assetTypeParam = '&id_asset_type=0&include_non_active=false&include_no_qr=false';
            }
            let self = this;
            fetch('/api/v1/asset/filtered?limit=25' + fromParam + placeParam + assetParam + assetTypeParam + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    self.selectAllAssets = false;
                    self.assets = [];
                    self.totalAssets = data.recordsTotal;
                    self.totalInactiveAssets = data.inactiveTotal;
                    for (var j = 0; j < data.data.length; j++) {
                        let asset = data.data[j];
                        let placeId = asset.id_place;
                        for (var i = 0; i < self.places.length; i++) {
                            if (self.places[i].id_place === placeId) {
                                asset.placeName = self.places[i].name;
                            }
                        }
                        asset.tempIdAssetType = 0;
                        self.assets.push(asset);
                    }
                    self.assetsTotalPages = data.recordsTotal / 25;
                    self.assetsTotalPages = Math.ceil(self.assetsTotalPages);
                    for (var k = 0; k < self.assets.length; k++) {
                        let asset = self.assets[k];
                        asset.tempIdAssetType = 0;
                    }
                    self.loading = false;
                });
            });
        },
        selectAssetsWithoutType() {
            this.selectedAssetTypeId = 0;
            this.selectedAssetTypeName = '';
            this.selectAssetsWithout = !this.selectAssetsWithout;
            this.getAssets();
        },
        resetAssets() {
            this.selectedAssetTypeToAssignName = '';
            this.selectedAssetTypeToAssignId = 0;
            this.approveAssetModalOpen = false;
            this.getAssets();
        },
        nextPage() {
            this.assetsCurrentPage++;
            this.getAssets();
        },
        prevPage() {
            this.assetsCurrentPage--;
            this.getAssets();
        },
        firstPage() {
            this.assetsCurrentPage = 1;
            this.getAssets();
        },
        lastPage() {
            this.assetsCurrentPage = this.assetsTotalPages;
            this.getAssets();
        },
        searchPlaces() {
            if (this.placeSearchTerm.length > 2) {
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '&search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/withownerinfo?limit=300' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            self.places.push(place);
                        }
                        self.vveplaces = self.places;
                        self.vveplaces = uniqBy(self.places, 'name');
                        if (!self.places) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
            }
        },
        searchAssetTypes() {
            this.tempAssetTypes = [];
            if (this.assetTypeSearchTerm.length > 1) {
                let searchTerm = this.assetTypeSearchTerm.toLowerCase();
                for (var i = 0; i < this.assetTypes.length; i++) {
                    let assetType = this.assetTypes[i];
                    let assetName = assetType.display_name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssetTypes.push(assetType);
                    }
                }
            }
        },
        searchAssetTypesToAssign() {
            this.tempAssetTypesToAssign = [];
            if (this.assetTypeToAssignSearchTerm.length > 1) {
                let searchTerm = this.assetTypeToAssignSearchTerm.toLowerCase();
                for (var i = 0; i < this.assetTypes.length; i++) {
                    let assetTypeToAssign = this.assetTypes[i];
                    let assetName = assetTypeToAssign.display_name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssetTypesToAssign.push(assetTypeToAssign);
                    }
                    if (!this.tempAssetTypesToAssign.length) {
                        this.assetTypesToAssignNoResults = true;
                    }
                    else {
                        this.assetTypesToAssignNoResults = false;
                    }
                }
            }
            else {
                this.assetTypesToAssignNoResults = false;
            }
        },
        selectPlace(place) {
            this.places = [];
            this.vveplaces = [];
            this.placesDropdownToggled = false;
            this.selectedPlace = place;
            this.placeSearchTerm = '';
            this.assetsCurrentPage = 1;
            this.placeSearchTerm = '';
            this.getAssets();
        },
        selectAssetType(assetType) {
            this.selectAssetsWithout = false;
            this.assetTypesDropdownToggled = false;
            this.selectedAssetTypeName = assetType.display_name;
            if (assetType === 'none') {
                this.selectedAssetTypeId = 0;
            }
            else {
                this.selectedAssetTypeId = assetType.id_asset_type;
            }
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
            this.getAssets();
        },
        selectAssetTypeToAssign(assetTypeToAssign) {
            this.assetCounter = 0;
            this.assetTypeToAssignDropdownToggled = false;
            this.selectedAssetTypeToAssignName = assetTypeToAssign.display_name;
            this.selectedAssetTypeToAssignId = assetTypeToAssign.id_asset_type;
            this.tempAssetTypesToAssign = [];
            this.assetTypeToAssignSearchTerm = '';
            for (var i = 0; i < this.assets.length; i++) {
                let assetToAssign = this.assets[i];
                if (assetToAssign.id_asset_type === this.selectedAssetTypeToAssignId) {
                    assetToAssign.tempIdAssetType = assetToAssign.id_asset_type;
                }
            }
        },
        removePlaceSelection() {
            this.placesDropdownToggled = false;
            this.assetTypesDropdownToggled = false;
            this.selectedPlace = {};
            this.getAssets();
        },
        removeAssetTypeSelection() {
            this.placesDropdownToggled = false;
            this.assetTypesDropdownToggled = false;
            this.selectedAssetTypeId = 0;
            this.getAssets();
        },
        removeAssetTypeToAssignSelection() {
            this.assetTypeToAssignDropdownToggled = false;
            this.selectedAssetTypeToAssignId = 0;
            for (var i = 0; i < this.assets.length; i++) {
                let assetToAssign = this.assets[i];
                assetToAssign.tempIdAssetType = 0;
            }
        },
        toggleFilterActive() {
            this.filterActive = !this.filterActive
        },
        toggleFilterSticker() {
            this.filterSticker = !this.filterSticker
        },
        toggleFilterMain() {
            this.filterMain = !this.filterMain
        },
        selectAllAssetsToAssign() {
            this.assetCounter = 0;
            this.selectAllAssets = !this.selectAllAssets;
            for (var i = 0; i < this.assets.length; i++) {
                let assetToAssign = this.assets[i];
                if (this.selectAllAssets) {
                    assetToAssign.tempIdAssetType = this.selectedAssetTypeToAssignId;
                }
                else {
                    assetToAssign.tempIdAssetType = '';
                }
                if (assetToAssign.tempIdAssetType === this.selectedAssetTypeToAssignId) {
                    this.assetCounter++;
                }
                else {
                    this.assetCounter = 0;
                }
            }
        },
        selectAssetsToAssign() {
            this.approveAssetModalOpen = true;
            this.allAssets = false;
        },
        selectAllAssetsAndAssign() {
            this.allAssets = true;
            this.approveAssetModalOpen = true;
        },
        selectAssetToAssign(assetToAssign) {
            if (assetToAssign.tempIdAssetType === this.selectedAssetTypeToAssignId) {
                assetToAssign.tempIdAssetType = 0;
                this.assetCounter--;
            }
            else {
                assetToAssign.tempIdAssetType = this.selectedAssetTypeToAssignId;
                this.assetCounter++;
            }
        },
        selectAssetToEdit(id_asset) {
            this.selectedAssetId = id_asset;
            this.$router.push('/buildingmanager/asset-detail?asset=' + id_asset);
        },
        search($event) {
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                this.getAssets();
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
                this.getAssets();
            }
            if($event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.getAssets();
            }
        },
        clearSearch() {
            this.searchActive = false;
            this.searchTerm = '';
            this.getAssets();
        },
        closeAssetTypesDropdown() {
            this.assetTypesDropdownToggled = false;
        },
        closeAssetTypeToAssignDropdown() {
            this.assetTypeToAssignDropdownToggled = false;
        }
    }
}
</script>
