<template>
    <div class="modal-wrapper" v-if="profile.teams">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-users"></i>{{profile.colleagues.assign_colleague}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="assignColleague()">
                    <div class="dropdown-section">
                        <span class="dropdown" data-testid="colleaguesDropdown" v-on:click="colleagueDropdownToggled = !colleagueDropdownToggled" :class="{active: colleagueDropdownToggled}">
                            <span v-if="!selectedColleague.id_shiro_user">{{profile.colleagues.choose_colleague}} *</span>
                            <span v-if="selectedColleague.id_shiro_user" class="selected">{{selectedColleague.actual_name}}</span>
                            <i class="fas fa-chevron-down chevron"></i></span>
                        <div class="dropdown-list" v-if="colleagueDropdownToggled" :class="{search: colleagues.length > 9}">
                            <input type="text" @input="e => colleagueSearchTerm = e.target.value" :value="colleagueSearchTerm" placeholder="Zoek een collega..." @keyup="searchColleagues()" v-if="colleagues.length > 9"  />
                            <ul>
                                <li v-for="colleague in preferredColleagues" v-on:click="selectColleague(colleague)" :key="colleague.id_shiro_user" style="padding-right: 15px;">
                                    {{colleague.actual_name}} <span class="label" style="float: right; background: #6a6a6f; font-size: 13px; color: white; padding: 3px 10px; border-radius: 5px; font-weight: 500; width: auto;">{{ profile.colleagues.preferred }}</span>
                                </li>
                                <div class="line" style="float: left; width: 100%; height: 3px; background: #d9d9d9; margin-top: -2px;"></div>
                                <li v-for="colleague in colleagues" v-on:click="selectColleague(colleague)" :key="colleague.id_shiro_user" v-show="!tempColleagues.length && !colleaguesNoResults">
                                    {{colleague.actual_name}}
                                </li>
                                <li v-for="(colleague, index) in tempColleagues" v-on:click="selectColleague(colleague)" :key="index" v-show="tempColleagues.length">{{colleague.actual_name}}</li>
                                <li v-if="colleaguesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row" v-if="incidentInfo.quotation_value_supplier && selectedColleague.actual_name" style="margin-top: 32px;">
                        <div class="col">
                            <p><b>{{profile.colleagues.reason}}</b></p>
                            <div class="switch-wrapper switch-wrapper--full no-hover" :class="{active: notifyReporter}">
                                <div class="inner" data-testid="notifyReporterSwitch" @click="notifyReporter = !notifyReporter">
                                    <i class="fa-regular fa-comment"></i>                                    
                                    <p>{{profile.colleagues.quotation_check}}</p>
                                    <span class="label">{{profile.colleagues.quotation_check_text}}</span>
                                    <label class="switch">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" data-testid="submitColleagueAssign" class="button button--icon"><i class="fas fa-user"></i>{{profile.colleagues.assign_colleague}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            preferredColleagues: [],
            colleagues: [],
            colleagueSearchTerm: '',
            selectedColleague: {},
            tempColleagues: [],
            colleaguesNoResults: false,
            colleagueDropdownToggled: false,
            notifyReporter: true
        }
    },
    computed: mapState(['bmId']),
    props: ['incidentInfo'],
    created() {
        this.getColleagues();
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        getColleagues() {
            this.teams = [];
            let self = this;
            fetch('/api/v1/incidentcombined/preferredusers?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.profile_users.length; i++) {
                        let item = data.profile_users[i];  
                        item.preferred = true;
                        if (item.actual_name && !item.username.includes('DISABLED')) {
                            self.preferredColleagues.push(item);
                        }
                    }  
                    for (var i = 0; i < data.users.length; i++) {
                        let item = data.users[i];  
                        if (item.actual_name && !item.username.includes('DISABLED')) {
                            self.colleagues.push(item);
                        }
                    }  
                    self.colleagues = uniqBy(self.colleagues, 'id_shiro_user');   
                    self.colleagues = sortBy(self.colleagues, 'actual_name');  
                });
            });
        },
        searchColleagues() {
            this.tempColleagues = [];
            if (this.colleagueSearchTerm.length > 1) {
                let searchTerm = this.colleagueSearchTerm.toLowerCase();
                for (var i = 0; i < this.colleagues.length; i++) {
                    let colleague = this.colleagues[i];
                    let colleagueName = colleague.actual_name.toLowerCase();
                    if (colleagueName.includes(searchTerm)) {
                        this.tempColleagues.push(colleague);
                    }
                    if (!this.tempColleagues.length) {
                        this.colleaguesNoResults = true;
                    }
                    else {
                        this.colleaguesNoResults = false;
                    }
                }
            }
            else {
                this.colleaguesNoResults = false;
            }
        },
        selectColleague(colleague) {
            this.colleagueDropdownToggled = false;
            this.selectedColleague = colleague;
            this.tempColleagues = [];
            this.colleagueSearchTerm = '';
        },
        assignColleague() {
            if (this.incidentInfo.incident_status === 'new_') {
                this.setStatus();
            }
            else {
                this.setColleague();
            }        
        },
        setColleague() {
            let self = this;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('id_shiro_user', this.selectedColleague.id_shiro_user);
            fetch('/api/v1/incident/changeserviceuserto', {
                method: 'post',
                body: data
            }).then((resp) => {
                if (!resp.ok) {
                    self.error = true;
                }
                return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Collega toekennen',
                });
                if (self.notifyReporter && self.incidentInfo.quotation_value_supplier) {
                    self.$store.dispatch('updateColleagueAssigned', self.selectedColleague.actual_name);
                }
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('getIncidentInfo');
                self.closeModal();
            });  
        },
        setStatus() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('status', 'pending_buildingmanager');
            let self = this;
            fetch('/api/v1/incident/changestatus', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.setColleague();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
