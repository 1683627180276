<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-handshake"></i>{{profile.owners.agreementflow.agreement_flow}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.owners.agreementflow.confirm_info}} <br /><br /></p>
                <div class="button-wrap">
                    <span class="button button--icon button--right" data-testid="doUpdateInfo" @click="updateInfo()"><i class="fa-solid fa-check"></i> {{profile.owners.agreementflow.edit}}</span>
                    <span class="button button--icon button--right" data-testid="dontUpdateInfo" @click="closeModal()"><i class="fas fa-ban"></i> {{profile.owners.agreementflow.dont_edit}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    props: ['owner', 'mobileConfirm', 'emailConfirm'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        updateInfo() {
            if (this.mobileConfirm) {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.owner.id_owner + '][mobile]', '');
                let self = this;
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.deactivateAgreementFlow();
                });
            }
            if (this.emailConfirm) {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.owner.id_owner + '][email]', '');
                let self = this;
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.deactivateAgreementFlow();
            });
            }
        },
        deactivateAgreementFlow() {
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.owner.id_owner + '][agreement_flow]', 'NO_FLOW');
            let self = this;
            fetch('/api/v1/owner', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('success');
            });
        }
    }
}
</script>
