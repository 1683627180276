<template>
    <div v-if="!twinq">
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block">
                    <div class="profile-card asset" style="border-right: 0; width: 80%;">
                        <div class="row">
                            <div class="col-xs-12 col-sm-9">
                                <div class="profile-pic-wrapper" v-if="asset.file_upload && asset.file_upload.length">
                                    <div class="profile-pic" style="overflow: hidden;" :style="{ backgroundImage: 'url(' + thumbnail + ')', backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                                </div>
                                <h4 style="text-transform: capitalize;">{{profile.assets.asset}}</h4>
                                <div class="inactive-label" v-if="!asset.qr_code">
                                    <i class="fas fa-qrcode"></i> {{profile.assets.no_sticker}}
                                </div>
                                <span class="active-label" data-testid="assetActiveText" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                <span class="inactive-label" data-testid="assetInactiveText" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                                <h2>{{asset.name}}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="asset-buttons">
                        <span class="edit-button icon" data-testid="assetActivateButton" @click="saveAsset('visible')" v-if="(asset.hidden && asset.qr_code) && admin && !disableEdit" v-tooltip.right="profile.assets.assetmodal.activate_asset" style="right: 60px; top: 18px;"><i class="fas fa-toggle-on"></i></span>
                        <span class="edit-button icon" data-testid="assetDeactivateButton" @click="saveAsset('hidden')" v-if="(!asset.hidden && asset.qr_code) && admin && !disableEdit" v-tooltip.right="profile.assets.assettable.deactivateareyousure" style="right: 60px;"><i class="fas fa-toggle-off"></i></span>
                        <span class="edit-button icon" data-testid="assetStatusButton" @click="deactivateAssetModalOpen = true" v-if="admin && !disableEdit"><i class="far fa-trash-alt"></i></span>
                        <span class="edit-button icon" data-testid="connectAssetButton" @click="connectAssetModalOpen = true" v-if="admin && !asset.qr_code" style="right: 60px; top: 18px;" v-tooltip.right="profile.assets.generate_qr"><i class="fas fa-qrcode"></i></span>
                    </div>
                </div>
                <div class="row" v-if="passedAsset">
                    <div class="col">
                        <span class="original_bm" v-tooltip.right="profile.incident_detail.pass_tooltip_asset"><i class="fas fa-share"></i> {{asset.building_manager_name}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9">
                        <div class="block block--location_detail" :class="{tabbed: passedAsset}">
                            <div>
                                <section class="incident-head" style="padding-left: 20px;">
                                    <h1 data-testid="assetEditTitle">{{profile.accountPage.account.general}}</h1>
                                </section>
                                <section class="location-contents">
                                    <div class="location-info">
                                        <div class="info-row" data-testid="assetNameEditButton" @click="nameEdit = true">
                                            <label>{{profile.assets.assetmodal.name}}
                                                <span class="edit-button edit-button--small" v-if="!nameEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="assetSaveName" @click="saveName()" v-if="nameEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="nameEdit">
                                                <input type="text" data-testid="assetEditName" class="form-control" v-model="asset.name">
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetAssetNameText">{{asset.name}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row no-hover" data-testid="assetLocationEditButton" @click="placeEdit = true">
                                            <label v-if="!placeEdit">{{profile.assets.assetmodal.place}}
                                                <span class="edit-button edit-button--small" v-if="!placeEdit"><i class="fas fa-pencil-alt"></i></span>
                                            </label>
                                            <div v-if="placeEdit">
                                                <label>{{profile.assets.assetmodal.change_place}} <i class="fas fa-info-circle" v-tooltip.right="profile.assets.assetmodal.change_place_tooltip" style="cursor: pointer;"></i>
                                                    <span class="edit-button edit-button--small" data-testid="assetLocationSave" @click.stop="savePlace()" v-if="placeEdit"><i class="far fa-save"></i></span>
                                                </label>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="from-to">
                                                            <label>&nbsp;</label>
                                                            <div class="selected-place" v-if="placeInfo.name">
                                                                <h4 data-testid="assetLocationName">{{placeInfo.name}}</h4>
                                                                <p>{{placeInfo.address}} {{placeInfo.house_number}}, {{placeInfo.city}}</p>
                                                                <span>{{profile.incident_detail.floor_name}} {{placeInfo.floor_name}}</span>
                                                            </div>
                                                            <i class="fas fa-chevron-right"></i>
                                                            <i class="fas fa-chevron-down"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <form>
                                                            <label>{{profile.incidents.new_incident.choose_place}} *</label>
                                                            <div class="form-field clearable" :class="{focus:placeSearchTerm.length}" style="margin-bottom: 10px;">
                                                                <label v-if="!selectedPlace.address">{{profile.incidents.new_incident.loc_placeholder}}</label>
                                                                <div>
                                                                    <div class="selected-info seperate" v-if="selectedPlace.name">
                                                                        <h4 style="margin: 0; font-size: 14px;">{{selectedPlace.name}}</h4>
                                                                        <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                                                                        <span style="margin-top: -8px;">{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                                                                        <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                                                                    </div>
                                                                    <input type="text" data-testid="assetEditPlaceSearch" ref="place" class="form-control" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                                                                </div>
                                                            </div>
                                                            <ul v-if="places.length" class="dropdown-list" data-testid="assetEditPlaceDropdownList" style="padding: 0; margin-top: -12px;">
                                                                <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" v-on:click="selectPlace(place)">
                                                                    <h4 data-testid="assetEditPlaceDropdownResult">{{place.name}}</h4>
                                                                    <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                                                                    <span style="margin-top: -8px;">{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                                                                </li>
                                                            </ul>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetLocationNameText" style="white-space: normal;">{{placeInfo.name}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <label>{{profile.assets.assetmodal.id_asset}}</label>
                                            <div>
                                                <p data-testid="assetIdText">{{asset.id_asset}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row">
                                            <label>{{profile.assets.assettable.qr_code}}</label>
                                            <div>
                                                <p style="word-break: break-all;">{{asset.qr_code}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="assetKeyEditButton" @click="keyNumberEdit = true">
                                            <label>{{profile.assets.assetmodal.key_number}}
                                                <span class="edit-button edit-button--small" v-if="!keyNumberEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="assetKeySave" @click="saveKeyNumber()" v-if="keyNumberEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="keyNumberEdit">
                                                <input type="text" data-testid="assetEditKeyNumber" class="form-control" v-model="asset.key_number">
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetKeyText">{{asset.key_number}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row last" @click="externalIdEdit = true" v-if="canChangeExternalIds">
                                            <label>{{profile.assets.assetmodal.external_id}}
                                                <span class="edit-button edit-button--small" v-if="!externalIdEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click="saveExternalId()" v-if="externalIdEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="externalIdEdit">
                                                <input type="text" class="form-control" data-testid="assetEditIdExternal" v-model="asset.id_external">
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetExternalIdText" v-if="asset.id_external !== 'null'">{{asset.id_external}}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="info-row" data-testid="assetTypeEdit" @click="assetTypeEdit = true" style="overflow: hidden;">
                                            <label>{{profile.assets.assetmodal.assettype}}
                                                <span class="edit-button edit-button--small" v-if="!assetTypeEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="assetTypeSave" @click="saveAssetType()" v-if="assetTypeEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="assetTypeEdit">
                                                <div class="dropdown-section" style="margin-bottom: 20px;">
                                                    <span class="dropdown" data-testid="assetEditAssetTypeDropdown" :class="{active: assetTypeDropdownToggled}" v-on:click="assetTypeDropdownToggled = !assetTypeDropdownToggled">
                                                        <span v-if="!selectedAssetTypeId" class="placeholder">{{profile.assets.select_objecttype}}</span>
                                                        <span v-if="selectedAssetTypeId" class="selected">{{selectedAssetTypeName}}</span>
                                                        <i class="fas fa-chevron-down chevron"></i>
                                                    </span>
                                                    <div class="dropdown-list" data-testid="assetEditAssetTypeDropdownList" v-if="assetTypeDropdownToggled">
                                                        <ul>
                                                            <li v-on:click="selectAssetType('none')" class="n_a" data-testid="nvtAssetType">{{profile.places.n_a}} <i class="fas fa-times"></i></li>
                                                            <li v-for="assetType in assetTypes" v-on:click="selectAssetType(assetType)" :key="assetType.id">{{assetType.display_name}}</li>
                                                        </ul>
                                                    </div>
                                                </div>                                            
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeText">{{assetType.display_name}}</p>
                                            </div>
                                            <div class="notification notification--warning" v-if="assetTypeChanged" v-html="profile.assets.assettable.changetypesure"></div>
                                        </div>
                                        <div class="info-row" @click="extraDescrAEdit = true" v-if="assetType.extra_description_a">
                                            <label>{{assetType.extra_description_a}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrAEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('a')" v-if="extraDescrAEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrAEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_a}">
                                                    <input type="text" data-testid="assetEditAssetType_a" class="form-control" v-model="asset.extra_description_a">
                                                </div>                                              
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeAText">{{asset.extra_description_a}}</p>                                         
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrBEdit = true" v-if="assetType.extra_description_b">
                                            <label>{{assetType.extra_description_b}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrBEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('b')" v-if="extraDescrBEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrBEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_a}">
                                                    <input type="text" data-testid="assetEditAssetType_b" class="form-control" v-model="asset.extra_description_b">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeBText">{{asset.extra_description_b}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrCEdit = true" v-if="assetType.extra_description_c">
                                            <label>{{assetType.extra_description_c}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrCEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('c')" v-if="extraDescrCEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrCEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_c}">
                                                    <input type="text" data-testid="assetEditAssetType_c" class="form-control" v-model="asset.extra_description_c">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeCText">{{asset.extra_description_c}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrDEdit = true" v-if="assetType.extra_description_d">
                                            <label>{{assetType.extra_description_d}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrDEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('d')" v-if="extraDescrDEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrDEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_d}">
                                                    <input type="text" data-testid="assetEditAssetType_d" class="form-control" v-model="asset.extra_description_d">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeDText">{{asset.extra_description_d}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrEEdit = true" v-if="assetType.extra_description_e">
                                            <label>{{assetType.extra_description_e}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrEEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('e')" v-if="extraDescrEEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrEEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_e}">
                                                    <input type="text" data-testid="assetEditAssetType_e" class="form-control" v-model="asset.extra_description_e">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeEText">{{asset.extra_description_e}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrFEdit = true" v-if="assetType.extra_description_f">
                                            <label>{{assetType.extra_description_f}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrFEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('f')" v-if="extraDescrFEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrFEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_f}">
                                                    <input type="text" data-testid="assetEditAssetType_f" class="form-control" v-model="asset.extra_description_f">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeFText">{{asset.extra_description_f}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrGEdit = true" v-if="assetType.extra_description_g">
                                            <label>{{assetType.extra_description_g}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrGEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('g')" v-if="extraDescrGEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrGEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_g}">
                                                    <input type="text" data-testid="assetEditAssetType_g" class="form-control" v-model="asset.extra_description_g">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeHText">{{asset.extra_description_g}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrHEdit = true" v-if="assetType.extra_description_h">
                                            <label>{{assetType.extra_description_h}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrHEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('h')" v-if="extraDescrHEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrHEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_h}">
                                                    <input type="text" data-testid="assetEditAssetType_h" class="form-control" v-model="asset.extra_description_h">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeGText">{{asset.extra_description_g}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrIEdit = true" v-if="assetType.extra_description_i">
                                            <label>{{assetType.extra_description_i}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrIEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('i')" v-if="extraDescrIEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrIEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_i}">
                                                    <input type="text" data-testid="assetEditAssetType_i" class="form-control" v-model="asset.extra_description_i">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeIText">{{asset.extra_description_i}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="info-row" @click="extraDescrJEdit = true" v-if="assetType.extra_description_j">
                                            <label>{{assetType.extra_description_j}}
                                                <span class="edit-button edit-button--small" v-if="!extraDescrJEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" @click.stop="saveExtraDescr('j')" v-if="extraDescrJEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="extraDescrJEdit">
                                                <div class="form-field focus" :class="{focus:assetType.extra_description_j}">
                                                    <input type="text" data-testid="assetEditAssetType_j" class="form-control" v-model="asset.extra_description_j">
                                                </div>                                                 
                                            </div>
                                            <div v-else>
                                                <p data-testid="assetTypeJText">{{asset.extra_description_j}}</p>                                                                           
                                            </div>
                                        </div>
                                        <div class="button button--neutral asset-history" style="float: right; text-align: center; bottom: auto; right: 0; top: auto; position: relative;" @click="assetRecordingsModalOpen = true" v-if="actionListsPlanned.length || actionListsStarted.length || actionListsCompleted.length || actionListsArchived.length"><i class="fas fa-list"></i> {{ profile.actionable.recordings }}</div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <Media :media="media" :movs="movs" :asset="asset" :admin="admin" @getAsset="getAsset()" />
                        <div class="block block--incident_detail" v-if="incidents.length">
                            <div class="incident-head">
                                <h1>{{profile.incident_detail.history_of_asset}}</h1>
                            </div>
                            <div style="margin-top: 20px; max-height: 400px; overflow: auto;">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 15%;">{{profile.incidents.incidenttable.id_incident}}</th>
                                            <th scope="col" style="width: 20%;">{{profile.s1mone.stickertable.created}}</th>
                                            <th scope="col" style="width: 45%;">{{profile.incidents.incidenttable.description}}</th>
                                            <th scope="col" style="width: 20%;">{{profile.incidents.incidenttable.status}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in incidents" :key="index" @click="toIncident(item.id_incident)">
                                            <td>{{item.id_incident}}</td>
                                            <td>{{item.date}}</td>
                                            <td>{{item.incident_description}}</td>
                                            <td v-if="!profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status]}}</td>
                                            <td v-if="profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status].buildingmanager}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="block block--incident_detail location" style="min-height: 0;" v-if="asset.qr_code">
                            <section class="incident-head">
                                <h1>S1-Code</h1>
                            </section>
                            <img :src="'/api/v1/asset/qrcodeasimage?id_asset=' + asset.id_asset" class="qr-code" style="margin: 10px auto -5px auto; width: 150px; display: block;" v-tooltip.right="asset.qr_code" />
                        </div>
                        <div class="block block--incident_detail location">
                            <section class="incident-head" style="margin-bottom: 20px;">
                                <h1>{{profile.assets.assetmodal.place}}</h1>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <a :href="mapsUrl" target="_blank">
                                        <img :src="mapsApiUrl" id="address-map" />
                                    </a>
                                </div>
                            </div>
                            <p style="margin-bottom: 0;">
                                <span style="display: block;">{{placeInfo.name}}
                                    <img src="@/assets/icons/flags/it.svg" v-if="placeInfo.country === 'IT'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/de.svg" v-if="placeInfo.country === 'DE'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/fr.svg" v-if="placeInfo.country === 'FR'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/esp.svg" v-if="placeInfo.country === 'ES'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/lu.svg" v-if="placeInfo.country === 'LU'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/be.svg" v-if="placeInfo.country === 'BE'" style="width: 15px; margin-left: 5px;">
                                </span>
                                <router-link tag="a" v-bind:to="'/buildingmanager/location-detail?id_place=' + asset.id_place">{{placeInfo.name}}
                                    <img src="@/assets/icons/flags/it.svg" v-if="placeInfo.country === 'IT'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/de.svg" v-if="placeInfo.country === 'DE'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/fr.svg" v-if="placeInfo.country === 'FR'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/esp.svg" v-if="placeInfo.country === 'ES'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/lu.svg" v-if="placeInfo.country === 'LU'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/be.svg" v-if="placeInfo.country === 'BE'" style="width: 15px; margin-left: 5px;">
                                </router-link>
                                {{placeInfo.address}} {{placeInfo.house_number}}<br />
                                {{placeInfo.zipcode}} {{placeInfo.city}}<br />
                                {{profile.incident_detail.floor_name}}: {{placeInfo.floor_name}}<br />
                            </p>
                        </div>
                        <div class="block block--incident_detail location" style="min-height: 0;" v-if="asset.categories && asset.categories.length">
                            <section class="incident-head">
                                <h1>{{ profile.assets.categories }}</h1>                                   
                            </section>
                            <div class="row">
                                <div class="col">
                                    <div style="margin-top: 10px; max-height: 615px; overflow: auto;">
                                        <div v-for="category in asset.categories" :key="category.id_incident_category" class="list-item" style="cursor: auto;">
                                            <span v-if="locale === 'nl'">{{ category.dutch_name }}</span>
                                            <span v-else>{{ category.english_name }}</span>
                                        </div>
                                    </div>                                 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AssetRecordingsModal v-if="assetRecordingsModalOpen" @close="assetRecordingsModalOpen = false" :actionListsPlanned="actionListsPlanned" :actionListsStarted="actionListsStarted" :actionListsCompleted="actionListsCompleted" :actionListsArchived="actionListsArchived" :qrCode="asset.qr_code" />
        <RemoveFileModal v-if="removeFileModalOpen" @close="removeFileModalOpen = false" @success="getAsset()" :file="file" :asset="asset" />
        <AssetHistoryModal v-if="assetHistoryModalOpen" @close="assetHistoryModalOpen = false" :assetId="assetId" />
        <DeactivateAssetModal v-if="deactivateAssetModalOpen" @close="deactivateAssetModalOpen = false" :asset="asset" :selectedPlace="selectedPlace" :selectedAssetId="selectedAssetId" :selectedAssetTypeId="selectedAssetTypeId" />
        <ConnectAssetModal v-if="connectAssetModalOpen" @close="connectAssetModalOpen = false" @success="getAsset()" :asset="asset" />
   </div>
</template>

<script>
import { mapState } from 'vuex';
import { setLocale } from '@/mixins/setLocale.js';
import Media from "@/components/asset/Media.vue";
import AssetRecordingsModal from "@/components/modals/recordings/AssetRecordingsModal.vue";
import ConnectAssetModal from "@/components/modals/ConnectAssetModal.vue";
import AssetHistoryModal from "@/components/modals/AssetHistoryModal.vue";
import DeactivateAssetModal from "@/components/modals/DeactivateAssetModal.vue";
import RemoveFileModal from "@/components/modals/RemoveFileAssetModal.vue";
import moment from 'moment';

export default {
    mixins: [setLocale],
    components: {
        Media,
        AssetRecordingsModal,
        AssetHistoryModal,
        DeactivateAssetModal,
        RemoveFileModal,
        ConnectAssetModal
    },
    data() {
        return {
            profile: {},
            assetId: 0,
            asset: {},
            placeInfo: {},
            mapsApiUrl: '',
            mapsUrl: '',
            locationInfo: {},
            assets: [],
            assetTypes: [],
            assetTypeDropdownToggled: false,
            selectedAssetTypeId: 0,
            selectedAssetTypeName: '',
            isSpace: true,
            selectedAssetId: 0,
            canChangeExternalIds: false,
            assetInfoEdit: false,
            placeEdit: false,
            nameEdit: false,
            keyNumberEdit: false,
            externalIdEdit: false,
            assetTypeEdit: false,
            extraDescrAEdit: false,
            extraDescrBEdit: false,
            extraDescrCEdit: false,
            extraDescrDEdit: false,
            extraDescrEEdit: false,
            extraDescrFEdit: false,
            extraDescrGEdit: false,
            extraDescrHEdit: false,
            extraDescrIEdit: false,
            extraDescrJEdit: false,
            admin: false,
            assetType: {},
            assetTypeChanged: false,
            qrImage: '',
            locationDropdownToggled: false,
            placeSearchTerm: '',
            places: [],
            noPlacesResults: false,
            selectedPlace: {},
            changePlace: false,
            removeAsset: false,
            assetHistoryModalOpen: false,
            passedAsset: false,
            deactivateAssetModalOpen: false,
            removeFileModalOpen: false,
            media: [],
            movs: [],
            totalMedia: 0,
            actionListsPlanned: [],
            actionListsStarted: [],
            actionListsCompleted: [],
            actionListsArchived: [],
            assetRecordingsModalOpen: false,
            incidents: [],
            disableEdit: false,
            connectAssetModalOpen: false,
            thumbnail: ''
        }
    },
    computed: mapState(['mapsKey', 'twinq']),
    created() {
        document.title = 'S1MONE | ' + this.profile.incidents.incidenttable.asset;
        let queryString = window.location.search;
        this.assetId = queryString.split('=')[1];
        this.getAsset();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAsset() {
            this.connectAssetModalOpen = false;
            this.removeFileModalOpen = false;
            this.movs = [];
            this.media = [];
            let self = this;
            fetch('/api/v1/asset/byid?id_asset=' + this.assetId)
            .then(response => { response.json().then(
                function(data) {
                    self.assetType = {};
                    self.assetTypeChanged = false;
                    if (data.file_upload.length) {
                        for (var i = 0; i < data.file_upload.length; i++) {
                            let media = data.file_upload[i];
                            if (media.content_type !== 'video/quicktime' && media.file_url) {
                                media.popover = false;
                                media.rotate = 1;
                                media.order = 1; 
                                media.selected = false;
                                let locale = localStorage.getItem('S1-locale');
                                if (locale === 'nl') {
                                    media.createdDate  = moment(media.created).locale('nl').format('D MMMM YYYY');
                                }
                                if (locale === 'en') {
                                    media.createdDate  = moment(media.created).locale('en').format('MMMM D YYYY');
                                }
                                if (data.main_image_id_file_upload && media.id_file_upload === data.main_image_id_file_upload) {
                                    self.thumbnail = media.file_url;
                                }
                                self.media.push(media);
                            }
                            if (media.content_type === 'video/quicktime') {
                                self.movs.push(media);
                            }
                        }
                        if (!data.main_image_id_file_upload && self.media.length) {
                            self.thumbnail = self.media[0].file_url;
                        }
                        self.totalMedia = self.media.length + self.movs.length;
                    }            
                    self.asset = data;
                    self.selectedAssetTypeId = self.asset.id_asset_type;
                    if (self.asset.name === null) {
                        self.asset.name = '';
                    }
                    if (self.asset.key_number === null) {
                        self.asset.key_number = '';
                    }
                    if (self.asset.extra_description_a === null) {
                        self.asset.extra_description_a = '';
                    }
                    if (self.asset.extra_description_b === null) {
                        self.asset.extra_description_b = '';
                    }
                    if (self.asset.extra_description_c === null) {
                        self.asset.extra_description_c = '';
                    }
                    if (self.asset.extra_description_d === null) {
                        self.asset.extra_description_d = '';
                    }
                    if (self.asset.extra_description_e === null) {
                        self.asset.extra_description_e = '';
                    }
                    if (self.asset.extra_description_f === null) {
                        self.asset.extra_description_f = '';
                    }
                    if (self.asset.extra_description_g === null) {
                        self.asset.extra_description_g = '';
                    }
                    if (self.asset.extra_description_h === null) {
                        self.asset.extra_description_h = '';
                    }
                    if (self.asset.extra_description_i === null) {
                        self.asset.extra_description_i = '';
                    }
                    if (self.asset.extra_description_j === null) {
                        self.asset.extra_description_j = '';
                    }
                    self.getAssetHistory();
                    self.getPlace();
                    self.getAssetTypes();
                    self.getAssets();
                    self.getUserRights();
                    self.getActionLists();
                    if (self.asset.partof_id_asset) {
                        self.isSpace = false;
                    }
                    if (self.asset.id_external === 'null') {
                        self.asset.id_external = '';
                    }
                });
            });
        },
        getAssetHistory() {
            let self = this;
            fetch('/api/v1/incidentcombined/previousincidentsbyasset?id_asset=' + this.asset.id_asset)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                        self.incidents.push(item);
                    }
                });
            });
        },
        getPlace() {
            let self = this;
            fetch('/api/v1/place/byid?id_place=' + this.asset.id_place)
            .then(response => { response.json().then(
                function(data) {
                    self.placeInfo = data;
                    self.selectedPlaceName = self.placeInfo.name;
                    self.selectedPlace.id_place = self.placeInfo.id_place;
                    // Add space in zipcode if it ends in two letters
                    if (/[a-zA-Z]{2}$/.test(self.placeInfo.zipcode)) {
                        self.placeInfo.zipcode = self.placeInfo.zipcode.substring(0, self.placeInfo.zipcode.length - 2) + ' ' + self.placeInfo.zipcode.substring(self.placeInfo.zipcode.length - 2);
                    }
                    let gmapsAddress    = self.placeInfo.address + "+" + self.placeInfo.house_number + "+"+self.placeInfo.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=150x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                });
            });
        },
        getAssets() {
            let self = this;
            fetch('/api/v1/asset/filtered?id_place=' + this.asset.id_place)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let asset = data.data[i];
                        if (asset.holder) {
                            self.assets.push(asset);
                        }
                    }
                    if (self.asset.partof_id_asset) {
                        for (var j = 0; j < self.assets.length; j++) {
                            let asset = self.assets[j];
                            if (self.asset.partof_id_asset === asset.id_asset) {
                                self.selectedAssetId = asset.id_asset;
                            }
                        }
                    }
                });
            });
        },
        getAssetTypes() {
            let self = this;
            fetch('/api/v1/asset_type')
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    for (var i = 0; i < self.assetTypes.length; i++) {
                        let assetType = self.assetTypes[i];
                        if (self.asset.id_asset_type === assetType.id_asset_type) {
                            self.assetType = assetType;
                        }
                        if (self.selectedAssetTypeId === assetType.id_asset_type) {
                            self.selectedAssetTypeName = assetType.display_name;
                        }
                    }
                    if (self.assetType.extra_description_a_default && !self.asset.extra_description_a) {
                        self.asset.extra_description_a = self.assetType.extra_description_a_default;
                    }
                    if (self.assetType.extra_description_b_default && !self.asset.extra_description_b) {
                        self.asset.extra_description_b = self.assetType.extra_description_b_default;
                    }
                    if (self.assetType.extra_description_c_default && !self.asset.extra_description_c) {
                        self.asset.extra_description_c = self.assetType.extra_description_c_default;
                    }
                    if (self.assetType.extra_description_d_default && !self.asset.extra_description_d) {
                        self.asset.extra_description_d = self.assetType.extra_description_d_default;
                    }
                    if (self.assetType.extra_description_e_default && !self.asset.extra_description_e) {
                        self.asset.extra_description_e = self.assetType.extra_description_e_default;
                    }
                    if (self.assetType.extra_description_f_default && !self.asset.extra_description_f) {
                        self.asset.extra_description_f = self.assetType.extra_description_f_default;
                    }
                    if (self.assetType.extra_description_g_default && !self.asset.extra_description_g) {
                        self.asset.extra_description_g = self.assetType.extra_description_g_default;
                    }
                    if (self.assetType.extra_description_h_default && !self.asset.extra_description_h) {
                        self.asset.extra_description_h = self.assetType.extra_description_h_default;
                    }
                    if (self.assetType.extra_description_i_default && !self.asset.extra_description_i) {
                        self.asset.extra_description_i = self.assetType.extra_description_i_default;
                    }
                    if (self.assetType.extra_description_j_default && !self.asset.extra_description_j) {
                        self.asset.extra_description_j = self.assetType.extra_description_j_default;
                    }
                });
            });
        },
        getUserRights() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.disableEdit = data.disable_edit_basedata;
                    self.admin = data.building_manager_admin;
                    if (data.can_change_external_ids) {
                        self.canChangeExternalIds = true;
                    }
                    if (data.id_building_manager !== self.asset.id_building_manager) {
                        self.admin = false;
                        self.passedAsset = true;
                    }
                });
            });
        },
        selectAssetType(assetType) {
            if (assetType === 'none') {
                this.assetTypeDropdownToggled = false;
                this.selectedAssetTypeName = this.profile.places.n_a;
                this.selectedAssetTypeId = 'none';
            }
            else {
                this.assetTypeDropdownToggled = false;
                this.selectedAssetTypeName = assetType.display_name;
                this.selectedAssetTypeId = assetType.id_asset_type;
            }
            if (this.selectedAssetTypeId !== this.asset.id_asset_type) {
                this.assetTypeChanged = true;
            }
            else {
                this.assetTypeChanged = false;
            }
        },
        saveMainObject() {
            const data = new URLSearchParams();
            data.append('id_asset', this.asset.id_asset);
            data.append('partof_id_asset', 0);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveName() {
            const data = new URLSearchParams();
            data.append('qr_code', this.asset.qr_code);
            data.append('id_asset', this.asset.id_asset);
            data.append('name', this.asset.name);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.nameEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveKeyNumber() {
            const data = new URLSearchParams();
            data.append('qr_code', this.asset.qr_code);
            data.append('id_asset', this.asset.id_asset);
            data.append('key_number', this.asset.key_number);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.keyNumberEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveExternalId() {
            const data = new URLSearchParams();
            data.append('qr_code', this.asset.qr_code);
            data.append('id_asset', this.asset.id_asset);
            data.append('id_external', this.asset.id_external);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.externalIdEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveAssetType() {
            if (this.selectedAssetTypeId === null || this.selectedAssetTypeId === 'none') {
                this.selectedAssetTypeId = 0;
            }
            const data = new URLSearchParams();
            data.append('qr_code', this.asset.qr_code);
            data.append('id_asset', this.asset.id_asset);
            data.append('id_asset_type', this.selectedAssetTypeId);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.assetTypeChanged = false;
                self.assetTypeEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getAsset();
            });
        },
        saveExtraDescr(type) {
            const data = new URLSearchParams();
            data.append('qr_code', this.asset.qr_code);
            data.append('id_asset', this.asset.id_asset);
            data.append('id_asset_type', this.selectedAssetTypeId);
            if (type === 'a') {
                data.append('extra_description_a', this.asset.extra_description_a);
                this.extraDescrAEdit = false;
            }
            if (type === 'b') {
                data.append('extra_description_b', this.asset.extra_description_b);
                this.extraDescrBEdit = false;
            }
            if (type === 'c') {
                data.append('extra_description_c', this.asset.extra_description_c);
                this.extraDescrCEdit = false;
            }
            if (type === 'd') {
                data.append('extra_description_d', this.asset.extra_description_d);
                this.extraDescrDEdit = false;
            }
            if (type === 'e') {
                data.append('extra_description_e', this.asset.extra_description_e);
                this.extraDescrEEdit = false;
            }
            if (type === 'f') {
                data.append('extra_description_f', this.asset.extra_description_f);
                this.extraDescrFEdit = false;
            }
            if (type === 'g') {
                data.append('extra_description_g', this.asset.extra_description_g);
                this.extraDescrGEdit = false;
            }
            if (type === 'h') {
                data.append('extra_description_h', this.asset.extra_description_h);
                this.extraDescrHEdit = false;
            }
            if (type === 'i') {
                data.append('extra_description_i', this.asset.extra_description_i);
                this.extraDescrIEdit = false;
            }
            if (type === 'j') {
                data.append('extra_description_j', this.asset.extra_description_j);
                this.extraDescrJEdit = false;
            }
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        savePlace() {
            if (this.selectedPlace.id_place) {
                const data = new URLSearchParams();
                data.append('id_place', this.selectedPlace.id_place);
                data.append('qr_code', this.asset.qr_code);
                data.append('id_asset', this.asset.id_asset);
                this.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Objectdetail object omhangen',
                });
                let self = this;
                fetch('/api/v1/asset/update', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.placeSearchTerm = '';
                    self.selectedPlace = {};
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.placeEdit = false;
                    self.getAsset();
                });
            }
            else {
                this.selectedPlace = {};
                this.placeEdit = false;
            }
        },
        saveAsset(type) {
            const data = new URLSearchParams();
            data.append('qr_code', this.asset.qr_code);
            if (this.selectedPlace.id_place) {
                data.append('id_place', this.selectedPlace.id_place);
                this.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Objectdetail object omhangen',
                });
            }
            data.append('id_asset', this.asset.id_asset);
            data.append('holder', this.asset.holder);
            data.append('partof_id_asset', this.selectedAssetId);
            data.append('key_number', this.asset.key_number);
            data.append('link_360', this.asset.link_360);
            data.append('name', this.asset.name);
            if (this.selectedAssetTypeId === null || this.selectedAssetTypeId === 'none') {
                this.selectedAssetTypeId = 0;
            }
            if (this.canChangeExternalIds && this.asset.id_external) {
                data.append('id_external', this.asset.id_external);
            }
            if (this.selectedAssetTypeId) {
                data.append('id_asset_type', this.selectedAssetTypeId);
            }
            if (this.asset.id_asset_type && this.selectedAssetTypeId !== this.asset.id_asset_type) {
                data.append('extra_description_a', '');
                data.append('extra_description_b', '');
                data.append('extra_description_c', '');
                data.append('extra_description_d', '');
                data.append('extra_description_e', '');
                data.append('extra_description_f', '');
                data.append('extra_description_g', '');
                data.append('extra_description_h', '');
                data.append('extra_description_i', '');
                data.append('extra_description_j', '');
            }
            if (this.asset.id_asset_type && this.selectedAssetTypeId === this.asset.id_asset_type) {
                data.append('extra_description_a', this.asset.extra_description_a);
                data.append('extra_description_b', this.asset.extra_description_b);
                data.append('extra_description_c', this.asset.extra_description_c);
                data.append('extra_description_d', this.asset.extra_description_d);
                data.append('extra_description_e', this.asset.extra_description_e);
                data.append('extra_description_f', this.asset.extra_description_f);
                data.append('extra_description_g', this.asset.extra_description_g);
                data.append('extra_description_h', this.asset.extra_description_h);
                data.append('extra_description_i', this.asset.extra_description_i);
                data.append('extra_description_j', this.asset.extra_description_j);
            }
            if (type === 'hidden') {
                data.append('hidden', true);
            }
            if (type === 'visible') {
                data.append('hidden', false);
            }
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.selectedPlace = {};
                self.assetInfoEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getAsset();
            });
        },
        searchPlaces() {
            if (this.placeSearchTerm.length > 2) {
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '?search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/withownerinfo' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            self.places.push(place);
                        }
                        if (!self.places) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
            }
        },
        selectPlace(place) {
            this.locationDropdownToggled = false;
            this.placeSearchTerm = '';
            this.places = [];
            this.selectedPlace = place;
        },
        removePlaceSelection() {
            this.selectedPlace = {};
            this.places = [];
        },
        zoom(e){
            let offsetX;
            let offsetY;
            let x;
            let y;
            let zoomer = e.currentTarget;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        },
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        openPopover(item) {
            item.popover = true;
            console.log(item);
            document.querySelector('body').classList.add('media-open');
        },
        closePopover() {
            document.querySelector('body').classList.remove('media-open');
            for (var i = 0; i < this.media.length; i++) {
                this.media[i].popover = false;
            }
        },
        selectFileToRemove(item) {
            this.removeFileModalOpen = true;
            this.file = item;
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        },
        getActionLists() {
            if (this.asset.qr_code) {
                this.actionsListsPlanneed = [];
                this.actionListsCompleted = [];
                this.actionListsArchived = [];
                let self = this;
                fetch('/api/v1/plannedactionlist/byqrcodewithactions?qr_code=' + this.asset.qr_code)
                .then(response => { response.json().then(
                    function(data) {
                        for (var i = 0; i < data.length; i++) {
                            let list = data[i];
                            let locale = localStorage.getItem('S1-locale');
                            console.log(list.completed_date);
                            if (locale === 'nl') {
                                list.latestDate  = moment(list.latest_possible).locale('nl').format('D MMMM YYYY');
                                if (list.completed_date) {
                                    list.completedDate  = moment(list.completed_date).locale('nl').format('D MMMM YYYY');
                                }
                            }
                            if (locale === 'en') {
                                list.latestDate  = moment(list.latest_possible).locale('en').format('MMMM D YYYY');
                                if (list.completed_date) {
                                    list.completedDate  = moment(list.completed_date).locale('en').format('MMMM D YYYY');
                                }
                            }
                            if (list.status === 'planned') {
                                self.actionListsPlanned.push(list);
                            }
                            if (list.status === 'started') {
                                self.actionListsStarted.push(list);
                            }
                            if (list.status === 'completed') {
                                self.actionListsCompleted.push(list);
                            }
                            if (list.status === 'archived') {
                                self.actionListsArchived.push(list);
                            }
                        }
                    });
                });     
            }
        },
        toIncident(id) {
            this.$router.push('/buildingmanager/incident-detail?id=' + id);
        }
    }
}
</script>
